import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import WidgetBot from "@widgetbot/react-embed";
import "./Discord.css";
import ReactGA from "react-ga";

function Discord({}) {
	useEffect(() => {
		ReactGA.pageview(window.location.pathname);
	}, []);
	return (
		<div className="widget_height_root bg-primary-background">
			<WidgetBot
				className="widget_height"
				style={{
					width: "100%",
				}}
				server="964089462328000523"
				channel="964105075364605982"
			/>
		</div>
	);
}

export default Discord;
