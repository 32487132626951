import { Avatar, Button, Modal, TextField, Typography, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";

import { Axios } from "../../../core/axios";
import { useAuth } from "../../../contexts/AuthContext";
import "./AllComment.css";
import { useNavigate } from "react-router-dom";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "80vw",
	bgcolor: "background.paper",
	borderRadius: 2,
	boxShadow: 24,
	outline: "none",
};

const AllComment = ({ open, nft, handleClose }) => {
	const { user } = useAuth();

	const [comments, setComments] = useState([]);
	const [comment, setComment] = useState("");

	const [isLiked, setLiked] = useState(nft.isLovedMySelf);

	const navigate = useNavigate();

	const handleComment = async (e) => {
		e.preventDefault();

		try {
			const com = {
				author: {
					username: user.username,
				},
				text: comment,
			};

			setComments([com, ...comments]);

			setComment("");

			await Axios.post(`/assets/${nft._id}/comments`, {
				theComment: comment,
			});
		} catch (error) {
			alert(error.message);
		}
	};
	console.log("nft: ", nft);

	const loadComments = async () => {
		try {
			const { data } = await Axios.get(`/assets/${nft._id}/comments`);

			console.log(data);
			setComments(data.comments);
		} catch (error) {
			alert(error.message);
		}
	};

	useEffect(() => {
		loadComments();
	}, []);

	const handleLike = async () => {
		if (user) {
			try {
				setLiked(!isLiked);
				// setNumberOfLoves(isLiked ? numberOfLoves - 1 : numberOfLoves + 1);

				await Axios.put(`/assets/reaction/${nft._id}`);
			} catch (error) {
				setLiked(!isLiked);
				alert(error.message);
				navigate("/addwallet");
			}
		} else {
			navigate("/addwallet");
		}
	};

	return (
		<>
			<Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<Box sx={style}>
					<Grid container>
						<Grid item xs={12} md={6} sx={{ overflow: "hidden", display: { xs: "flex", md: "flex" }, flexDirection: { xs: "column" }, height: { md: "83vh", xs: "50vh" }, background: "#f1f1f1", justifyContent: "center", alignItems: "center", position: "relative" }}>
							{nft.animation.url ? (
								<video style={{ objectFit: "contain" }} className="commentImg" autoPlay muted loop playsInline={true}>
									<source src={nft.animation.url} type="video/mp4" />
								</video>
							) : (
								<img className="commentImg" src={nft.images.image_url || "//via.placeholder.com/200x200.png?text=Image%20not%20found."} alt="" />
							)}

							<img
								onClick={handleLike}
								src={isLiked ? "/icons/likedButton.svg" : "/icons/likeButton.svg"}
								height={50}
								width={100}
								alt=""
								className="hover:scale-105 transition duration-150 ease-out transform cursor-pointer"
								style={{
									position: "absolute",
									bottom: "0",
									left: "0",
								}}
							/>
						</Grid>

						<Grid item xs={12} md={6} sx={{ p: 2 }}>
							<Box
								sx={{
									borderBottom: 1,
									borderColor: "#DBDBDB",
									pb: 1,
									display: {
										xs: "block",
										md: "block",
									},
								}}
							>
								<Box
									sx={{
										display: "flex",
										justifyContent: "flex-start",
										cursor: "pointer",
									}}
									onClick={() => navigate(`/profile/${nft.author._id}`)}
								>
									<Avatar sx={{ border: 1, borderColor: "#DBDBDB" }} alt="Travis Howard" src={nft.author.avatar} />
									<Typography sx={{ ml: { xs: 2, md: 2 }, fontSize: 14, fontWeight: 600, mt: 1, fontFamily: "arial" }} variant="h6" component="h6">
										{nft.author.username}
									</Typography>
								</Box>
							</Box>

							<Box
								sx={{
									mt: 3,
									height: {
										xs: comments.length === 0 ? 100 : 100,
										md: 400,
									},
									overflow: "auto",
								}}
							>
								{comments.map((item, idx) => (
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											pb: 2,
										}}
										key={idx}
									>
										<Avatar
											sx={{
												border: 1,
												borderColor: "#DBDBDB",
												width: 30,
												height: 30,
											}}
											alt="Travis Howard"
											src={item.author.avatar}
										/>
										<Typography sx={{ ml: 2, fontSize: 13, fontWeight: 600, fontFamily: "arial" }} variant="h6" component="h6">
											{item.author.username}
										</Typography>
										<Typography
											sx={{
												ml: 2,
												fontSize: 13,
												fontFamily: "arial",
												fontWeight: 300,
											}}
										>
											{item.text}
										</Typography>
									</Box>
								))}

								{comments.length === 0 && <Typography variant="h6">No comments right now 😔</Typography>}
							</Box>

							<Box>
								<Box>
									<p style={{ fontSize: 15, fontWeight: 600, fontFamily: "arial" }}>{nft.numberOfLoves} Likes</p>
								</Box>
								<Box sx={{ borderTop: 1, color: "#DBDBDB" }}>
									<form sx={{ display: "flex" }} onSubmit={handleComment}>
										<Grid container>
											<Grid item xs={9}>
												<TextField sx={{ width: "100%" }} placeholder="Please enter text" variant="standard" InputProps={{ disableUnderline: true }} value={comment} onChange={(e) => setComment(e.target.value)} />
											</Grid>
											<Grid item xs={2}>
												<Button type="submit" variant="text" sx={{ width: 10, mt: 1, mr: { xs: 1 } }}>
													Post
												</Button>
											</Grid>
										</Grid>
									</form>
								</Box>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Modal>
		</>
	);
};

export default AllComment;
