import axios from "axios";
import { useEffect, useState } from "react";
import "./conversation.css";
import { Axios } from "../../../../core/axios";
import { useAuth } from "../../../../contexts/AuthContext";

export default function Conversation({ conversation, currentUser }) {
	const [user, setUser] = useState(null);
	// const { user } = useAuth();
	const PF = process.env.REACT_APP_PUBLIC_FOLDER;

	useEffect(() => {
		const friendId = conversation.members.find((m) => m !== currentUser._id);

		const getUser = async () => {
			try {
				const { data: userData } = await Axios.get(`/profile/${friendId}`);
				console.log(userData);
				setUser(userData.data);
				// setUser(res.data);
			} catch (err) {
				console.log(err);
			}
		};
		getUser();
	}, [currentUser, conversation]);

	return (
		<>
			{user && (
			<div className="hover:bg-gray-100 px-4 py-2 rounded-lg transition duration-150 ease-out dark:hover:bg-gray-600">
				<div className="flex items-center space-x-2">
					<img
						className="h-10 w-10 rounded-full"
						src={
							user ? user.avatar || "" : ""
							// ? PF + user.profilePicture
							// : PF + "person/noAvatar.png"
						}
						alt=""
					/>
					<span className="conversationName">
						{user ? user.username || "" : ""}
					</span>
				</div>
			</div>
			)}
		</>
	);
}
