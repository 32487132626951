import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import PostCard from "./Cards/PostCard";
import { Axios } from "../core/axios";
import { LinearProgress, Button, Box } from "@mui/material";

function AllPosts() {
	const [params] = useSearchParams();

	const [posts, setPosts] = useState([]);
	const [isLoadable, setLoadable] = useState(false);
	const [isLoading, setLoading] = useState(true);

	const loadPosts = async () => {
		try {
			const query = params.get("search");
			const skip = posts.length;

			setLoading(true);
			let url = `/assets/feed?skip=${skip}`;
			if (query) {
				url = `/assets/search?query=${query}&skip=${skip}`;
			}

			const { data } = await Axios.get(url);
			// console.log(data);

			setLoading(false);

			setLoadable(data.data.length === 21);

			setPosts([...posts, ...data.data]);
		} catch (error) {
			setLoading(false);
			alert(error.message);
		}
	};

	useEffect(() => {
		loadPosts();
	}, []);

	return (
		<>
			{isLoading && (
				<div
					style={{
						position: "fixed",
						width: "100%",
						top: 0,
						left: 0,
						right: 0,
						zIndex: 99999,
					}}
				>
					<LinearProgress color="error" />
				</div>
			)}

			<div className="px-4 sm:px-10 py-4 space-y-4 sm:space-y-0 gap-4 w-full  grid grid-cols-1 md:grid-cols-3  mx-auto">
				{posts.length === 0 && !isLoading ? (
					<div>
						<h1>Nothing to display.</h1>
					</div>
				) : (
					posts.map((post, idx) => post.images.image_url&&<PostCard nft={post} key={idx} />)
				)}
			</div>
			{isLoadable && (
				<h1 className="text-black cursor-pointer px-4 text-md text-bold pb-10 dark:text-white" onClick={loadPosts}>
					{isLoading ? "Loading..." : "Load More"}
				</h1>
			)}
		</>
	);
}

export default AllPosts;
