import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextField, CircularProgress } from "@mui/material";
// import Registration from "./Registration/Registration";
import { Axios } from "../core/axios";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";

function Register({ handleClose, open }) {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [isLoading, setLoading] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const handleRegister = async (e) => {
		e.preventDefault();

		if (firstName === "" || lastName === "" || email === "" || password === "") return;

		try {
			setLoading(true);
			const { data } = await Axios.post("/auth/register", {
				firstName,
				lastName,
				email,
				password,
			});

			localStorage.setItem("user", JSON.stringify(data));
			window.location.href = "/profile";
		} catch (error) {
			alert(error.message);
			setLoading(false);
		}
	};

	useEffect(() => {
		ReactGA.pageview(window.location.pathname);
	}, []);

	return (
		<div className="bg-black text-white min-h-screen flex flex-col items-center justify-center">
			<div className="h-full flex w-full flex-col items-center">
				<img src="/icons/logoSkull.svg" alt="" className="h-12 w-12" />
				<div className="w-full sm:w-[500px] mx-auto p-8">
					<h1 className="text-2xl mb-8 font-bold">Register</h1>
					<input type="text" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} className="bg-transparent border-b pb-2 mb-4 border-gray-500 outline-none text-xs w-full" />
					<input type="text" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} className="bg-transparent border-b pb-2 mb-4 border-gray-500 outline-none text-xs w-full" />
					<input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} className="bg-transparent border-b pb-2 mb-4 border-gray-500 outline-none text-xs w-full" />
					<div className="flex border-gray-500 border-b pb-2 items-end mb-8">
						<input type={`${showPassword ? "text" : "password"}`} placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} className="bg-transparent  outline-none  text-xs w-full" />
						<img onClick={() => setShowPassword(!showPassword)} src="/icons/eye.svg" alt="" className="pb-2 cursor-pointer" />
					</div>
					<div onClick={handleRegister} className="py-3 cursor-pointer bg-[#F99D5E] flex justify-center rounded-lg">
						<h1 className="text-white font-bold text-xs">Register</h1>
					</div>
					<Link to="/login" className="py-3 cursor-pointer bg-primary-background mt-4 shadow-md shadow-black flex justify-center rounded-lg">
						<h1 className="text-white font-bold text-xs">SIGN IN</h1>
					</Link>
				</div>
				<img src="/icons/gaggle_icon.svg" alt="" className="h-6 fixed bottom-8" />
			</div>
		</div>
	);
}

export default Register;
