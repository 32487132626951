import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
// import CommentIcon from "@mui/icons-material/Comment";
// import nftImf from "../../../img/nft1.png";
// import avatar from "../../../img/avatar.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
// import { Axios } from "../../../core/axios";
import "swiper/css/navigation";
import { Navigation, Pagination, Scrollbar, A11y, Keyboard, Autoplay, EffectFade } from "swiper";

// import QRCode from "react-qr-code";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import "./UserAllPost.css";

var QRCode = require("qrcode.react");

const UserAllPost = ({ handleClose, loadMore, open = false, nfts, initialSlide = 0, isSelectMode }) => {
	const isMobile = () => window.matchMedia && window.matchMedia("(max-width: 600px)").matches;
	console.log("UserAllPost", nfts);
	if (open) {
		return (
			<div style={{ position: "fixed", width: "100%", height: "100%", top: 0, left: 0, zIndex: 5000 }}>
				<CloseIcon style={{ width: 50, height: 50, position: "absolute", zIndex: 5001, right: 50, top: 50, cursor: "pointer", color: "white" }} onClick={handleClose} />

				<Swiper
					loop={false}
					style={{
						backgroundColor: "black",
					}}
					modules={[Autoplay, EffectFade, Navigation, Pagination, Scrollbar, A11y, Keyboard]}
					autoplay={{
						delay: 5000,
						disableOnInteraction: false,
					}}
					effect="fade"
					fadeEffect={{ crossFade: true }}
					pagination={{ clickable: true }}
					Scrollbar={{ draggable: true }}
					navigation={true}
					keyboard={true}
					initialSlide={initialSlide}
					spaceBetween={0}
					slidesPerView={1}
					onReachEnd={async (swiper) => {
						swiper.autoplay.stop();
						if (!isSelectMode) {
							await loadMore();
						}
						swiper.autoplay.start();
					}}
				>
					{nfts.map((nft, idx) => (
						<SwiperSlide
							key={idx}
							style={{
								height: "100vh",
								width: "100vh",
							}}
						>
							<Box style={{ height: "100%", width: "100%" }}>
								<Box style={{ height: "75%", width: "100%", paddingTop: 10 }}>
									{nft.animation.url ? (
										<video
											style={{
												objectFit: "contain",
												width: "100%",
												height: "100%",
											}}
											autoPlay
											muted
											loop
											playsInline
										>
											<source src={nft.animation.url} type="video/mp4" />
										</video>
									) : (
										<img
											className=""
											style={{
												objectFit: "contain",
											}}
											src={nft.images.image_url || "//via.placeholder.com/200x200.png?text=Image%20not%20found."}
											alt=""
										/>
									)}
								</Box>
								<Box
									style={{ height: "20%", textAlign: "center", justifyContent: "center", alignItems: "center" }}
									sx={{
										// position: "absolute",
										// left: "50%",
										// transform: "translate(-50%, -50%)",
										// bottom: 20,
										background: "rgba(0,0,0,0.5)",
										display: { md: "inline-flex", xs: "inline-flex" },
									}}
								>
									<QRCode style={{ width: isMobile() ? 50 : 100, height: isMobile() ? 50 : 100 }} value={nft.permalink} />
									{/* <img src="/images/qr-code.png" alt="qr-code" style={{ width: 100, height: 100 }} /> */}

									<Box sx={{ color: "#fff", p: 1, ml: 5, alignSelf: "center" }}>
										<Typography variant="h5">{nft.name}</Typography>

										<Typography>Creator: {nft.creator || "Not Found"}</Typography>
									</Box>
								</Box>
							</Box>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		);
	}
};
export default UserAllPost;
