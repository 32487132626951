import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Axios } from "../core/axios";
import Typography from "./Typography";
import useDarkLightMode from "../hook/useDarkMode";
import { useAuth } from "../contexts/AuthContext";
import { isMobile } from "react-device-detect";

function Header({ globalLoading, setGlobalLoading }) {
	const location = useLocation();
	const navigate = useNavigate();
	const { user } = useAuth();
	const [isDark, setIsDark] = React.useState(true);
	useEffect(() => {
		let mode = localStorage.getItem("theme");
		if (mode === "dark") {
			setIsDark(true);
		}
		if (mode === "light") {
			setIsDark(false);
		}
	}, []);
	if (isMobile) {
		return (
			<div>
				{location.pathname !== "/login" && location.pathname !== "/register" && location.pathname !== "/forgotPassword" && location.pathname !== "/password-reset" && (
					<div className="h-[93px] bg-[#ffffff] drop-shadow-lg flex justify-between items-end px-4 py-4 dark:bg-black">
						<div className="flex space-x-2 items-center">
							<Link to={"/"}>
								<img src="/icons/logoSkull.svg" className="h-8 " alt="logo" />
							</Link>
							<div
								className="flex-row items-center"
								style={{
									alignItems: "center",
								}}
							>
								<Link to={"/"} className="items-start">
									{/*<img src="/icons/gaggle_icon.svg" className="h-8 " alt="logo"/>*/}
									<p
										className="font-markerFelt text-[20px] text-start"
										style={{
											color: "#F99D5E",
										}}
									>
										Gaggle
									</p>
								</Link>
								<p className="font-markerFelt text-[8px] text-[#000] dark:text-[#fff]">Showcase Your Portfolio</p>
							</div>
						</div>
						{location.pathname !== "/notifications" ? (
							<div
								className="flex space-x-2"
								style={{
									alignItems: "center",
								}}
							>
								<div className="flex space-x-5">
									{location.pathname !== "/messenger" && (
										<div
											onClick={() => {
												window.location.href = "/messenger";
											}}
											style={{ cursor: "pointer" }}
											title="Chat with others"
										>
											<img
												src={isDark ? "/icons/chatLight.svg" : "/icons/chat.svg"}
												alt="inbox"
												className=""
												style={{
													width: "23px",
													height: "23px",
												}}
											/>
										</div>
									)}

									{location.pathname === "/profile" ? (
										<Link to={"/settings"}>
											<img title="Settings" src={isDark ? "/icons/cogs.svg" : "/icons/cogsBlack.svg"} alt="inbox" className="" />
										</Link>
									) : (
										<Link to={"/notifications"} className="relative">
											<img title="Shows all notifications" src={isDark ? "/icons/bell.svg" : "/icons/bellBlack.svg"} alt="inbox" className="" />
											<div className="top-0 right-0 h-2 w-2 rounded-full absolute bg-red-400"></div>
										</Link>
									)}
									{location.pathname === "/profile" ? (
										<img
											src={isDark ? "/icons/sync.svg" : "/icons/syncBlack.svg"}
											height={20}
											width={20}
											title="Sync your NFTs"
											style={{ cursor: "pointer" }}
											onClick={async () => {
												try {
													setGlobalLoading(true);
													const { data } = await Axios.post("/assets/synchronization");

													setGlobalLoading(false);
													// alert(data.message);
												} catch (error) {
													setGlobalLoading(false);
													alert(error.message);
												}
											}}
											alt="inbox"
											className=""
										/>
									) : // <img
									// 	src="/icons/wallet_icon.svg"
									// 	height={27}
									// 	width={27}
									// 	style={{ cursor: "pointer" }}
									// 	onClick={() => {
									// 		window.location.href = "/addwallet";
									// 	}}
									// ></img>
									null}
								</div>
								{(location.pathname === "/" || location.pathname === "home") && (
									<img
										src={isDark ? "/icons/light.svg" : "/icons/dark.svg"}
										height={35}
										width={35}
										title={isDark ? "Switch to Light Mode" : "Switch to Dark Mode"}
										style={{ cursor: "pointer" }}
										onClick={async () => {
											if (isDark) {
												localStorage.setItem("theme", "light");
												setIsDark(false);
											} else {
												localStorage.setItem("theme", "dark");
												setIsDark(true);
											}
											window.location.reload();
										}}
										alt="dark mode"
										className=""
									/>
								)}

								{(location.pathname === "/" || location.pathname === "home") && (
									<img
										src={user ? "/icons/wallet_linked.svg" : "/icons/credit.svg"}
										height={30}
										width={30}
										title={user ? "Sync your Wallet" : "Add Wallet"}
										style={{ cursor: "pointer" }}
										onClick={async () => {
											if (user) {
												// navigate("/profile");
												setGlobalLoading(true);
												const { data } = await Axios.post("/assets/synchronization");

												setGlobalLoading(false);
											} else {
												navigate("/addwallet");
											}
										}}
										alt="dark mode"
										className="rotate-180"
									/>
								)}
							</div>
						) : (
							<div onClick={() => window.history.go(-1)} className="text-[#F99D5E] cursor-pointer text-xs font-bold">
								done
							</div>
						)}
					</div>
				)}
			</div>
		);
	} else {
		return (
			<div>
				{location.pathname !== "/login" && location.pathname !== "/register" && location.pathname !== "/forgotPassword" && location.pathname !== "/password-reset" && (
					<div className="h-[93px] bg-[#ffffff] drop-shadow-lg flex justify-between items-center px-4 py-4 dark:bg-black">
						<div
							className="flex space-x-4 items-center"
							style={{
								alignItems: "flex-center",
							}}
						>
							<Link to={"/"}>
								<img src="/icons/logoSkull.svg" className="h-8 " alt="logo" />
							</Link>
							{(location.pathname === "/" || location.pathname === "home") && (
								<img
									src={isDark ? "/icons/light.svg" : "/icons/dark.svg"}
									height={37}
									width={37}
									title={isDark ? "Switch to Light Mode" : "Switch Dark Mode"}
									style={{ cursor: "pointer" }}
									onClick={async () => {
										if (isDark) {
											localStorage.setItem("theme", "light");
											setIsDark(false);
										} else {
											localStorage.setItem("theme", "dark");
											setIsDark(true);
										}
										window.location.reload();
									}}
									alt="dark mode"
								/>
							)}
							{/*<Link to={"/search"} className="flex items-center" style={{*/}
							{/*	width: "35px",*/}
							{/*	height: "35px"*/}
							{/*}} >*/}
							{location.pathname !== "/search" && (
								<img
									onClick={() => {
										window.location.href = "/search";
									}}
									title="Search"
									src={isDark ? "/icons/searchWhite.svg" : "/icons/searchBlack.svg"}
									className="h-6 rotate-180 cursor-pointer"
									alt="logo"
								/>
							)}
							{/*</Link>*/}
						</div>

						<div
							className="flex-row items-center"
							style={{
								alignItems: "center",
							}}
						>
							<Link to={"/"} className="items-center">
								{/*<img src="/icons/Gaggle_c2.png" width={400} alt="logo"/>*/}
								<p
									className="font-markerFelt text-[35px] text-center"
									style={{
										color: "#F99D5E",
									}}
								>
									Gaggle
								</p>
							</Link>
							<p className="font-markerFelt text-[12px] text-[#000] dark:text-[#fff]">Showcase Your Portfolio</p>
						</div>

						{location.pathname !== "/notifications" ? (
							<div
								className="flex space-x-2"
								style={{
									alignItems: "center",
								}}
							>
								<div className="flex space-x-5">
									{location.pathname !== "/messenger" && (
										<div
											onClick={() => {
												window.location.href = "/messenger";
											}}
											style={{ cursor: "pointer", alignSelf: "center" }}
											title="Chat with other users"
										>
											<img
												src={isDark ? "/icons/chatLight.svg" : "/icons/chat.svg"}
												alt="inbox"
												className=""
												style={{
													width: "23px",
													height: "23px",
												}}
											/>
										</div>
									)}

									{location.pathname === "/profile" ? (
										<Link to={"/settings"} title="Settings">
											<img src={isDark ? "/icons/cogs.svg" : "/icons/cogsBlack.svg"} alt="inbox" className="" />
										</Link>
									) : (
										<Link
											to={"/notifications"}
											style={{
												alignSelf: "center",
											}}
											className="relative"
											title="Shows all notifications"
										>
											<img src={isDark ? "/icons/bell.svg" : "/icons/bellBlack.svg"} alt="inbox" className="" />
											<div className="top-0 right-0 h-2 w-2 rounded-full absolute bg-red-400"></div>
										</Link>
									)}
									{location.pathname === "/profile" ? (
										<img
											src={isDark ? "/icons/sync.svg" : "/icons/syncBlack.svg"}
											height={20}
											width={20}
											title="Sync your NFTs"
											style={{ cursor: "pointer" }}
											onClick={async () => {
												try {
													setGlobalLoading(true);
													const { data } = await Axios.post("/assets/synchronization");

													setGlobalLoading(false);
													// alert(data.message);
												} catch (error) {
													setGlobalLoading(false);
													alert(error.message);
												}
											}}
											alt="inbox"
											className=""
										/>
									) : // <img
									// 	src="/icons/wallet_icon.svg"
									// 	height={27}
									// 	width={27}
									// 	style={{ cursor: "pointer" }}
									// 	onClick={() => {
									// 		window.location.href = "/addwallet";
									// 	}}
									// ></img>
									null}

									{(location.pathname === "/" || location.pathname === "home") && (
										<img
											src={user ? "/icons/wallet_linked.svg" : "/icons/credit.svg"}
											height={20}
											width={30}
											title={user ? "Sync your wallet" : "Add your wallet"}
											style={{ cursor: "pointer" }}
											onClick={async () => {
												if (user) {
													// navigate("/profile");
													setGlobalLoading(true);
													const { data } = await Axios.post("/assets/synchronization");

													setGlobalLoading(false);
												} else {
													navigate("/addwallet");
												}
											}}
											alt="dark mode"
											className="rotate-180"
										/>
									)}
								</div>
							</div>
						) : (
							<div onClick={() => window.history.go(-1)} className="text-[#F99D5E] cursor-pointer text-xs font-bold">
								done
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
}

export default Header;
