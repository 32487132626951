import React, { useState, useEffect, useRef } from "react";
import { Avatar, Box, Container, Grid, Button, LinearProgress, Typography, Modal } from "@mui/material";
import { utils } from "ethers";

import { isMobile } from "react-device-detect";
import screenfull from "screenfull";

import ReactGA from "react-ga";

import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { Axios } from "../core/axios";
import UserAllPost from "../componnent/UserProfile/UserAllPost/UserAllPost";
import Followers from "../componnent/UserProfile/Followers";
import SingleItem from "../componnent/UserProfile/SingleItem";
import useDarkLightMode from "../hook/useDarkMode";
import SalesCard from "../componnent/Cards/SalesCard";
import axios from "axios";
import { Chart as ChartJS, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
ChartJS.register(...registerables);
function Profile({ name, globalLoading, setGlobalLoading }) {
	const [follow, setFollow] = useState(false);

	const followClick = () => {
		setFollow(!follow);
	};

	const navigate = useNavigate();

	const [currentChat, setCurrentChat] = useState(null);

	// const { user } = useAuth();
	const [open, setOpen] = useState(false);

	const [portfolioValue, setPortfolioValue] = useState(0);

	const [shouldOpenFollowerDialog, setShoudOpenFollowerDialog] = useState(false);
	const [isNeedToShowFollowers, setIsNeedToShowFollowers] = useState(false);

	const [isSelectMode, setIsSelectMode] = useState(false);

	var [array, setArray] = useState([]);
	var [secoud, setSecoud] = useState(0);
	var [prevScrollLeft, setScrollLeft] = useState(10);

	const [selectedNfts, setSelectedNfts] = useState([]);

	const [initialSlide, setInitialSlide] = useState(0);
	const divRref = useRef(null);

	const [portfolioChange, setPortfolioChange] = useState(0);

	const [graphDataFewDays, setGraphDataFewDays] = useState(null);
	const [openGraph, setOpenGraph] = useState(false);

	// useEffect(() => {
	// 	// const interval = setInterval(() => {
	// 	//   // setSeconds(seconds => seconds + 1);
	// 	// }, 1000);
	// 	// return () => clearInterval(interval);
	// 	if(user && _id === user._id) {

	// 	}
	// 	else {
	// 		return;
	// 	}
	// 	if (array.length === 0) {
	// 		fetch("https://us-central1-niftyagency-36aa8.cloudfunctions.net/getTickerData")
	// 			.then((res) => {
	// 				// return resolve(res.json())
	// 				// console.log(res.json())
	// 				return res.json();
	// 			})
	// 			.then((data) => {
	// 				console.log(data);
	// 				setArray(data);
	// 			})
	// 			.catch((err) => {
	// 				console.error(err);
	// 				// return resolve({"status": "success"})
	// 			});
	// 	} else {
	// 		// console.log("Already loaded")

	// 		const interval = setInterval(() => {
	// 			setSecoud((secouds) => secouds + 1);

	// 			// console.log(secoud)
	// 			// console.log(divRref.current.scrollLeft)
	// 			if (divRref.current.scrollLeft != prevScrollLeft) {
	// 				setScrollLeft(divRref.current.scrollLeft);
	// 				divRref.current.scrollTo(secoud, 0);
	// 			} else {
	// 				setSecoud(0);
	// 				divRref.current.scrollTo(0, 0);
	// 			}
	// 		}, 25);
	// 		return () => {
	// 			// console.log("Clear")
	// 			clearInterval(interval);
	// 		};
	// 	}
	// }, [array, secoud]);

	const handleOpen = (initialSlide) => {
		if (!isMobile) {
			screenfull.toggle();
		}
		setOpen(true);
		setInitialSlide(initialSlide);
	};
	const handleClose = () => {
		if (!isMobile) {
			screenfull.toggle();
		}
		setOpen(false);
	};
	// useDarkLightMode();

	const handleFollowerDialogClose = () => {
		// screenfull.toggle();
		setShoudOpenFollowerDialog(false);
	};

	const { _id } = JSON.parse(localStorage.getItem("user")) || {};

	const { id } = useParams();

	const [user, setUser] = useState();
	const [posts, setPosts] = useState([]);
	const [isLoading, setLoading] = useState(true);
	const [isFollowedMySelf, setIsFollowedMySelf] = useState(false);
	const [numberOfFollowers, setNumberOfFollowers] = useState(0);

	const loadUser = async () => {
		console.log("HIi");
		try {
			setLoading(true);
			setUser(null);
			setPosts([]);

			const { data: userData } = await Axios.get(`/profile/${id || _id}`);

			setUser(userData.data);
			console.log(userData.data);
			setIsFollowedMySelf(userData.data.isFollowedMySelf);
			setNumberOfFollowers(userData.data.numberOfFollowers);

			const { data } = await Axios.get(`assets/users/${id || _id}`);

			setPosts(data.data);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			alert(error.message);
		}
	};

	const loadPosts = async () => {
		try {
			setLoading(true);

			const { data } = await Axios.get(`assets/users/${id || _id}?skip=${posts.length}`);

			setPosts([...posts, ...data.data]);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			alert(error.message);
		}
	};

	const addWalletAddress = async () => {
		try {
			const walletAddress = prompt("Enter your wallet address...");

			if (utils.isAddress(walletAddress)) {
				setLoading(true);

				await Axios.post("/users/add-wallet-address", {
					walletAddress,
				});

				const { data } = await Axios.post("/assets/synchronization");

				setLoading(false);
				// alert(data.message);

				const user = JSON.parse(localStorage.getItem("user"));
				user.walletAddress = walletAddress;

				localStorage.setItem("user", JSON.stringify(user));
				window.location.href = "/profile";
			} else {
				setLoading(false);
				alert("Invalid wallet address.");
			}
		} catch (error) {
			setLoading(false);
			alert(error.message);
		}
	};

	const syncNfts = async () => {
		try {
			if (user.walletAddress) {
				setLoading(true);
				const { data } = await Axios.post("/assets/synchronization");

				setLoading(false);
				// alert(data.message);
			} else {
				alert("No wallet address linked with your account. Add wallet address first.");
			}
		} catch (error) {
			setLoading(false);
			alert(error.message);
		}
	};

	const handleMessage = async () => {
		navigate(`/messenger/${id}`);
	};

	const handleFollow = async () => {
		try {
			if (!isFollowedMySelf) {
				await Axios.post(`profile/${user._id}/follow`, {
					operationFor: "follow",
				});
			} else {
				await Axios.post(`profile/${user._id}/follow`, {
					operationFor: "unfollow",
				});
			}

			setIsFollowedMySelf(!isFollowedMySelf);
			setNumberOfFollowers(isFollowedMySelf ? numberOfFollowers - 1 : numberOfFollowers + 1);
		} catch (error) {
			alert(error.message);
		}
	};
	const timer = useRef();

	useEffect(async () => {
		if (user._id === _id) {
			var walletAddress = user.walletAddress[0];
			console.log(walletAddress);

			var { data } = await Axios.post(`/users/get24hFoorPrice`, {
				walletAddress: walletAddress,
			});
			setPortfolioChange(data.percentChange);

			var minval = await axios.get(`https://us-central1-niftyagency-36aa8.cloudfunctions.net/getwalletMinValue?walletAddress=${walletAddress}`);
			console.log(minval.data);

			timer.current = setInterval(async () => {
				var coinbase_usd = await axios.get("https://api.coinbase.com/v2/prices/ETH-USD/sell");
				var coinbase_usd_value = coinbase_usd.data.data.amount;
				var coinbase_eth_value = coinbase_usd_value * minval.data;
				console.log(`Coinbase ETH value: ${coinbase_eth_value}`);
				setPortfolioValue(coinbase_eth_value);
				// console.log(`Coinbase USD value: ${coinbase_usd_value}`);
			}, 5000);

			var data2 = await Axios.post(`/users/get7DaysFoorPrice`, {
				walletAddress: walletAddress,
			});
			data2 = data2.data;

			var floorPrices = [];
			var dates = [];
			for (var i = 0; i < data2.length; i++) {
				floorPrices.push(Number(data2[i].floor_price.toFixed(2)));

				// convert 2022-08-09T04:52:04.934Z to 4:52 PM (9 Aug) createdAt
				var _time = data2[i].createdAt.split("T")[1];
				var convertedTime = _time.split(":")[0] + ":" + _time.split(":")[1] + " " + (Number(_time.split(":")[0]) > 12 ? "PM" : "AM");

				var _date = data2[i].createdAt.split("T")[0];

				var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

				var month = months[_date.split("-")[1] - 1];
				var day = _date.split("-")[2];

				var timeAndDate = convertedTime + " (" + month + " " + day + ")";
				dates.push(timeAndDate);
			}
			console.log("---floorPricess---");
			console.log(floorPrices);
			console.log(dates);

			var _graphData = {
				labels: dates.reverse(),
				datasets: [
					{
						data: floorPrices.reverse(),
						// color: (opacity = 1) => `#000`, // optional
						// strokeWidth: 2 // optional
						label: "Price",
						fill: false,
						borderColor: "rgb(75, 192, 192)",
						tension: 0.1,
					},
				],
			};
			console.log("_graphData---------");
			console.log(_graphData);
			setGraphDataFewDays(_graphData);
		}
	}, [user]);

	useEffect(() => {
		return () => clearInterval(timer.current);
	}, []);

	useEffect(() => {
		loadUser();

		if (isMobile) {
		} else {
			screenfull.on("change", () => {
				if (!screenfull.isFullscreen) {
					setOpen(false);
				}
			});
		}
	}, [id]);
	console.log("user: ", user);

	useEffect(() => {
		ReactGA.pageview(window.location.pathname);
	}, []);

	return (
		<>
			<div className="bg-secondary-background min-h-screen dark:bg-primary-background">
				{(isLoading || globalLoading) && (
					<div
						style={{
							position: "fixed",
							width: "100%",
							top: 0,
							left: 0,
							right: 0,
							zIndex: 99999,
						}}
					>
						<LinearProgress color="error" />
					</div>
				)}
				{user && (
					<div>
						{/* {user && _id === user._id && (
							<>
						<div className="px-4 sticky top-0 z-10 bg-secondary-background left-0 right-0 dark:bg-primary-background ">
							<div className="flex justify-between pt-4">
								<h2 className="text-black  font-bold text-xs dark:text-white">Top NFT Sales (last hour)</h2>
							</div>
							<div ref={divRref} className=" scroll py-4 flex overflow-x-scroll scrollbar-hide  w-full space-x-2">
								{array.map((item) => (
									<SalesCard title={item.name} isUpAverage={item.isUpAverage} isUpSale={item.isUpSale} img={item.src.replaceAll("40", "50")} salesValue={item.salesValue} salesPercent={item.salesPercent} aurValue={item.averageValue} aurPercent={item.averagePercent} />
								))}
							</div>
						</div>
						<div className="w-full h-[1px] bg-gray-900"></div>
						</>
						)} */}
						<div className="p-4">
							<div className="flex space-x-2 items-center justify-center">
								<div className="rounded-full h-12 w-12">
									<img src={user.avatar} alt="" className="rounded-full w-full h-full" />
								</div>
								<div className="text-black space-y-1 w-40 dark:text-white">
									<h1
										className="flex flex-row text-sm font-bold"
										style={{
											alignItems: "center",
											fontFamily: "Roboto Condensed, sans-serif",
											fontWeight: "700",
										}}
									>
										{user.username}{" "}
									</h1>
									<h2
										className="flex flex-col text-xs"
										style={{
											fontFamily: "Roboto Condensed, sans-serif",
											fontWeight: "500",
										}}
									>
										{user.bio}
									</h2>

									{user._id === _id && (
										<div
											style={{
												display: "flex",
												flexDirection: "row",
												cursor: "pointer",
											}}
											onClick={() => {
												console.log("clicked");
												setOpenGraph(true);
											}}
										>
											<h1
												className="text-black dark:text-white"
												style={{
													// marginLeft: "0.5rem",
													fontSize: 18,
													fontWeight: "bold",
													// padding: "4px 4px",
													borderRadius: "5px",
													textAlign: "center",
													fontFamily: "Roboto Condensed, sans-serif",
													fontWeight: "700",
												}}
											>
												{/* Formate profolio value to amount by comma */}${Number(portfolioValue.toFixed(2)).toLocaleString()}{" "}
											</h1>
											<h1
												style={{
													marginLeft: 5,
													display: "flex",
													color: portfolioChange === 0 ? "black" : portfolioChange >= 0 ? "green" : "red",
													fontSize: 18,
													fontFamily: "RobotoCondensed-Bold",
													fontStyle: "normal",
													// paddingTop: 5,
													paddingBottom: 5,
													borderRadius: 10,
													// backgroundColor: '#000',
													// textAlign: 'center',
												}}
											>
												{"("}
												{Math.round(portfolioChange).toFixed(2)}
												{"%)"}
											</h1>
										</div>
									)}
								</div>
							</div>
							{_id !== user._id ? (
								<div className="grid text-sm grid-cols-2 gap-4 mt-4 sm:w-[500px] mx-auto">
									<div onClick={handleFollow} className={`${isFollowedMySelf ? "bg-black border border-2-[#F99D5E]" : "bg-[#F99D5E]"} cursor-pointer py-1 w-full rounded-md shadow-lg shadow-black flex justify-center`}>
										<h1
											className="text-white font-bold"
											style={{
												fontFamily: "Roboto Condensed, sans-serif",
												fontWeight: "700",
											}}
										>
											{isFollowedMySelf ? "Following" : "Follow"}
										</h1>
									</div>

									<div onClick={handleMessage} className="bg-black py-1 w-full cursor-pointer rounded-md shadow-lg shadow-black flex item-center space-x-1 justify-center">
										<img src="/icons/comment-alt-dots.svg" alt="" className="h-4 my-auto" />
										<h1
											className="text-white font-bold"
											style={{
												fontFamily: "Roboto Condensed, sans-serif",
												fontWeight: "700",
											}}
										>
											Message
										</h1>
									</div>
								</div>
							) : (
								<>
									{/* <h2
										className="text-xs"
										style={{
											fontSize: "0.8rem",
											color: "#ffffff",
											marginBottom: "0.5rem",
											textAlign: "center",
											marginTop: "2rem",
										}}
									>
										{user.walletAddress ? user.walletAddress[0] : ""}
									</h2> */}

									<div className="sm:w-[400px] mx-auto text-sm mt-4">
										<Link
											to={"/editProfile"}
											className="text-black font-bold cursor-pointer border border-[#F99D5E] py-1 flex justify-center rounded-lg dark:text-white"
											style={{
												fontFamily: "Roboto Condensed, sans-serif",
												fontWeight: "500",
											}}
										>
											Edit Profile
										</Link>
									</div>
								</>
							)}
						</div>
						<div className="h-[2px] w-full bg-gray-400 dark:bg-gray-900"></div>
						<div className="p-4 sm:w-[500px] mx-auto">
							<div className="grid grid-cols-3">
								<div className="text-black flex flex-col items-center justify-center dark:text-white">
									<h1
										className="text-lg font-bold"
										style={{
											fontFamily: "Roboto Condensed, sans-serif",
											fontWeight: "700",
										}}
									>
										{user.numberOfAssets}
									</h1>
									<h1
										className="text-md font-semibold"
										style={{
											fontFamily: "Roboto Condensed, sans-serif",
											fontWeight: "700",
										}}
									>
										NFTs
									</h1>
								</div>
								<div
									onClick={() => {
										if (numberOfFollowers) {
											setIsNeedToShowFollowers(true);
											setShoudOpenFollowerDialog(true);
										}
									}}
									className="text-black cursor-pointer flex flex-col items-center justify-center dark:text-white"
								>
									<h1
										className="text-lg font-bold"
										style={{
											fontFamily: "Roboto Condensed, sans-serif",
											fontWeight: "700",
										}}
									>
										{user.numberOfFollowers}
									</h1>
									<h1
										className="text-md font-semibold"
										style={{
											fontFamily: "Roboto Condensed, sans-serif",
											fontWeight: "700",
										}}
									>
										Followers
									</h1>
								</div>
								<div
									onClick={() => {
										if (user.numberOfFollowings > 0) {
											setIsNeedToShowFollowers(false);
											setShoudOpenFollowerDialog(true);
										}
									}}
									className="text-black cursor-pointer flex flex-col items-center justify-center dark:text-white"
								>
									<h1
										className="text-lg font-bold"
										style={{
											fontFamily: "Roboto Condensed, sans-serif",
											fontWeight: "700",
										}}
									>
										{user.numberOfFollowings}
									</h1>
									<h1
										className="text-md font-semibold"
										style={{
											fontFamily: "Roboto Condensed, sans-serif",
											fontWeight: "700",
										}}
									>
										Following
									</h1>
								</div>
							</div>
							<div className="grid text-sm grid-cols-2 gap-4 mt-4">
								<div onClick={() => handleOpen(0)} className="bg-[#F99D5E] cursor-pointer py-1 w-full rounded-md shadow-lg shadow-black item-center space-x-1  flex justify-center">
									<img src="/icons/display.svg" alt="" className="h-4 my-auto" />
									<h1
										className="text-white font-bold"
										style={{
											fontFamily: "Roboto Condensed, sans-serif",
											fontWeight: "700",
										}}
									>
										Display Mode
									</h1>
								</div>
								{_id === user._id && (
									<div
										onClick={() => {
											setIsSelectMode(!isSelectMode);
											if (!isSelectMode) {
												setSelectedNfts([]);
											}
										}}
										className="bg-black py-1 w-full cursor-pointer rounded-md shadow-lg shadow-black flex item-center justify-center"
									>
										<h1 className="text-white font-bold">Select</h1>
									</div>
								)}
							</div>
						</div>
						<div className="h-[2px] w-full bg-gray-400 dark:bg-gray-900"></div>
						<div className="grid grid-cols-4 gap-4 p-4">
							{/* {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
								<div>
									<img src="/images/nft1.png" className="h-16 rounded-lg" />
									</div>
								))} */}
							{posts.map((post, idx) => (
								<div>
									{/* <img src={post.images.image_url || "'https://via.placeholder.com/200x200.png?text=Image%20not%20found.'"} className="w-full rounded-lg" key={idx} /> */}
									<SingleItem isSelectMode={isSelectMode} nft={post} key={idx} setSelectedNfts={setSelectedNfts} allselectedNfts={selectedNfts} />
								</div>
							))}
						</div>
						<div className="pb-10">
							{user && user.numberOfAssets > 20 && posts.length < user.numberOfAssets && (
								<Box sx={{ p: 4, textAlign: "center" }}>
									<Button onClick={loadPosts}>{isLoading ? "Loading..." : "Load More"}</Button>
								</Box>
							)}
						</div>
					</div>
				)}
			</div>
			{open && <UserAllPost open={open} loadMore={loadPosts} initialSlide={initialSlide} nfts={isSelectMode ? selectedNfts : posts} handleClose={handleClose} isSelectMode={isSelectMode} />}
			{shouldOpenFollowerDialog && <Followers open={shouldOpenFollowerDialog} handleClose={handleFollowerDialogClose} isNeedToShowFollowers={isNeedToShowFollowers} userID={user._id === _id ? user._id : id} isOwnProfile={user._id === _id} />}

			<Modal open={openGraph && graphDataFewDays !== null} onClose={() => setOpenGraph(false)}>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
						height: "70vh",
						textAlign: "center",
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
					}}
				>
					<div
						style={{
							width: "100%",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							backgroundColor: "#fff",
							padding: 20,
							height: "100%",
							display: "flex",
						}}
					>
						<div>
							<h1
								style={{
									fontFamily: "RobotoCondensed-Light",
									fontSize: 20,
									color: "#000",
								}}
							>
								7 Days Floor Prices (ETH)
							</h1>
						</div>
						<div
							style={{
								marginTop: 20,
								width: "100%",
								height: "80%",
							}}
						>
							{/* <ScrollView
                        horizontal={true}
                        showsHorizontalScrollIndicator={false}
                        style={{
                            height: 400,
                        }}
                        > */}
							<Line
								data={graphDataFewDays}
								options={{
									maintainAspectRatio: false,
								}}
								height={200}
							/>
							{/* </ScrollView> */}
						</div>
						<div
							style={{
								backgroundColor: "#F99D5E",
								padding: 10,
								borderRadius: 10,
								marginTop: 20,
								width: 100,
								justifyContent: "center",
								alignItems: "center",
								color: "#fff",
								cursor: "pointer",
							}}
							onClick={() => setOpenGraph(false)}
						>
							Close
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
}

export default Profile;
