import React, {useEffect, useRef, useState} from "react";
import { Box, Grid } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import AllComment from "../AllPost/AllComment/AllComment";
import {Axios} from "../../core/axios";
import {useAuth} from "../../contexts/AuthContext";
import {useNavigate} from "react-router-dom";

const SingleItem = ({ nft, isSelectMode, setSelectedNfts, allselectedNfts }) => {
	const [open, setOpen] = useState(false);
	const [isSelected, setIsSelected] = useState(false);
	const [isLiked, setLiked] = useState(nft.isLovedMySelf);
	const [numberOfLoves, setNumberOfLoves] = useState(nft.numberOfLoves);
	const navigate = useNavigate();
	const { user } = useAuth();

	const videoRef = useRef(null);

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		if (!isSelectMode) {
			setIsSelected(false);
		}
	}, [isSelectMode]);
	console.log(nft);

	const handleLike = async (e) => {
		e.stopPropagation()
		if (user) {
			try {
				setLiked(!isLiked);
				setNumberOfLoves(isLiked ? numberOfLoves - 1 : numberOfLoves + 1);

				await Axios.put(`/assets/reaction/${nft._id}`);
			} catch (error) {
				setLiked(!isLiked);
				alert(error.message);
				navigate("/addwallet");
			}
		} else {
			navigate("/addwallet");
		}
	};

	return (
		<>
			<Grid  item sx={{ cursor: "pointer" }}>
				<Box
					onClick={() => {
						if (!isSelectMode) {
							setOpen(true);
						} else {
							if (isSelected) {
								var array = allselectedNfts.splice(
									allselectedNfts.findIndex((item) => item.id === nft.id),
									1
								);
								setSelectedNfts(array);
							} else {
								setSelectedNfts([...allselectedNfts, nft]);
							}
							console.log(allselectedNfts);
							setIsSelected(!isSelected);
						}
					}}
					width={"100%"}
					style={{
						objectFit: nft.images.image_url&&nft.images.image_url.includes(".svg") ? "contain" : "cover",
					}}
					sx={{
						backgroundImage: `url(${nft.images.image_url || "'https://via.placeholder.com/200x200.png?text=Image%20not%20found.'"})`,
						backgroundPosition: "center",
						backgroundRepeat: "no-repeat",
						backgroundSize: nft.images.image_url&&nft.images.image_url.includes(".svg") ? "contain" : "cover",
						backgroundColor: "#f1f1f1",
						height: {  xs: 80, sm: 200,  md: 350 },
						position: "relative",
						borderRadius: "10px",
						overflow: "hidden",
					}}
				>
					{/*{nft.animation.url && (*/}
					{/*	<video ref={videoRef} style={{ width: "100%", objectFit: "contain" }} muted loop playsInline={true}>*/}
					{/*		<source src={nft.animation.url} type="video/mp4" />*/}
					{/*	</video>*/}
					{/*)}*/}

					<div className="flex absolute bottom-0 justify-end items-end w-full">
						<div className={nft.permalink.includes("opensea")?"flex justify-center items-center md:h-[65px] md:w-[40px] h-[30px] w-[20px]":"flex justify-center items-center md:h-[55px] md:w-[40px] h-[28px] w-[20px]"}
							 onClick={(e)=>{
								 // window.open(nft.permalink, "_blank");
								 e.stopPropagation()
								 // Open Custom Tab with URL and make it's height full screen but width fixed
								 window.open(nft.permalink, '_blank', 'width=800,height=800,left=100,top=100');
							 }}
							 title="View the NFT"
							>
							{nft.permalink.includes("opensea")? (
								<img src="/icons/opensea.svg" height={45} width={45} alt="" className="hover:scale-105 transition duration-150 ease-out transform cursor-pointer" />
							):
								<img src="/icons/link.svg" height={45} width={45} alt="" className="hover:scale-105 transition duration-150 ease-out transform cursor-pointer" />
							}
							{/*<img src="/icons/opensea.svg" height={45} width={45} alt="" className="hover:scale-105 transition duration-150 ease-out transform cursor-pointer" />*/}
						</div>
						<div className="flex flex-col item-center justify-center">
							<h2 className="flex justify-center font-bold text-white text-[12px] md:text-[18px] ">{numberOfLoves} </h2>
							<div className="flex justify-center md:h-[60px] md:w-[75px] h-[30px] w-[40px] items-center">
							<img title={isLiked?"Dislike":"Like"} onClick={handleLike} src={isLiked?"/icons/likedButton.svg":"/icons/likeButton.svg"} alt="" className="hover:scale-105 transition duration-150 ease-out transform cursor-pointer" />
							</div>
						</div>
					</div>
					{nft.animation.url?
						<div className="absolute top-1/2 left-1/2"
							 style={{
								 transform: "translate(-50%, -50%)"
							 }}
							 onClick={(e)=>{
								 e.stopPropagation()
							 	setOpen(true)
							 }
							 }
						>
							<img src="/icons/play.svg" height={50} width={50}/>
						</div>:null}
					{/* <Box sx={{ background: "rgba(0,0,0,0.5)", color: "#fff", p: 1, position: "absolute", width: "100%", bottom: 0 }}>
						{nft.numberOfLoves} Likes | {nft.numberOfComments} Comments
					</Box> */}

					{isSelectMode ? (
						<Box
							onClick={() => {
								setIsSelected(!isSelected);
								if (isSelected) {
									var array = allselectedNfts.splice(
										allselectedNfts.findIndex((item) => item.id === nft.id),
										1
									);
									setSelectedNfts(array);
								} else {
									setSelectedNfts([...allselectedNfts, nft]);
								}
								console.log(allselectedNfts);
							}}
							sx={{ background: "rgba(0,0,0,0.5)", color: "#fff", p: 1, position: "absolute", width: "100%", top: 0 }}
						>
							{isSelected ? <CheckCircleIcon /> : <CheckCircleOutlineIcon />}
						</Box>
					) : null}
				</Box>
			</Grid>

			{open && <AllComment open={open} nft={nft} handleClose={handleClose} />}
		</>
	);
};

export default SingleItem;
