import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import PostCard from "../componnent/Cards/PostCard";
import { Axios } from "../core/axios";
import { LinearProgress, Button, Box } from "@mui/material";
import SingleItem from "../componnent/UserProfile/SingleItem";
import ReactGA from "react-ga";
import AllComment from "../componnent/AllPost/AllComment/AllComment";

function SavedImageComp({ post, idx, loadPostsAgain }) {
	const [params] = useSearchParams();
	const [open, setOpen] = useState(false);

	const handleClose = () => {
		setOpen(false);
		loadPostsAgain();
	};


	return (
		<>
			<div>
				<img onClick={()=>{
					setOpen(true);
				}} src={post.asset.images.image_url || "'https://via.placeholder.com/200x200.png?text=Image%20not%20found.'"} className="w-full sm:h-[200px] md:h-[400px] rounded-lg bg-cover" key={idx} />
				{/* <SingleItem isSelectMode={isSelectMode} nft={post.asset} key={idx} setSelectedNfts={setSelectedNfts} allselectedNfts={selectedNfts} /> */}
				{open && <AllComment open={open} nft={post.asset} handleClose={handleClose} />}
			</div>
			</>
	);
}

export default SavedImageComp;
