import React, { useState, useRef, useEffect } from "react";

import Lottie from "react-lottie";
import animationData from "./ConnectedAnim.json";

function WalledLinked({ name }) {
	const defaultOptions = {
		loop: false,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};

	// useEffect(() => {
	// 	// load after 1 second
	// 	setTimeout(() => {
	// 		window.open("gaggle://home", "_blank");
	// 	}, 1000);
	// }, []);

	return (
		<div className="bg-secondary-background min-h-screen">
			<div
				style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					height: "100%",
					// width: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
				}}
			>
				<Lottie
					options={defaultOptions}
					style={{
						width: "150px",
						height: "150px",
					}}
				/>
				<h1
					className="text-black-100 text-xl font-bold"
					style={{
						textAlign: "center",
					}}
				>
					Connected
				</h1>
				<p
					className="text-black-100 text-sm"
					style={{
						textAlign: "center",
						margin: "20px",
					}}
				>
					You can now continue using the Gaggle App.
				</p>
				{window.ethereum.isMetaMask ? null : (
					<button
						className="bg-primary-background hover:bg-primary-background-dark text-white font-bold py-2 px-4 rounded"
						style={{
							justifyContent: "center",
							width: "200px",
							alignSelf: "center",
						}}
						onClick={() => {
							if (window.ethereum.isMetaMask) {
								window.location.href = "https://api.gaggle.art/home";
							} else {
								window.open("gaggle://home", "_blank");
							}
						}}
					>
						Open Gaggle App
					</button>
				)}
			</div>
		</div>
	);
}

export default WalledLinked;
