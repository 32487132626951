import { Avatar, Button, Modal, TextField, Typography, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";

import { Axios } from "../../core/axios";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "40vw",
	bgcolor: "background.paper",
	borderRadius: 2,
	boxShadow: 24,

	outline: "none",
};

const Followers = ({ open, isNeedToShowFollowers, handleClose, userID, isOwnProfile }) => {
	const { user } = useAuth();
	const navigate = useNavigate();
	const [followers, setFollowers] = useState([]);
	const [followings, setFollowings] = useState([]);

	const loadFollowerAndFollowing = async () => {
		try {
			const { data } = await Axios.get(`/users/followers-followings-list/${isOwnProfile ? "" : userID}`);
			// console.log(data);
			setFollowers(data.data.followers);
			setFollowings(data.data.followings);
		} catch (error) {
			alert(error.message);
		}
	};

	useEffect(() => {
		loadFollowerAndFollowing();
	}, []);

	return (
		<>
			<Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<Box
					style={{
						padding: "1rem",
					}}
					sx={style}
				>
					{isNeedToShowFollowers
						? followers.map((user, idx) => (
								<Box key={idx} sx={{ display: "flex", justifyContent: "space-between", pb: 2 }}>
									<Box
										onClick={() => {
											handleClose();
											navigate(`/profile/${user._id}`);
										}}
										sx={{
											display: "flex",
											justifyContent: "flex-start",
											cursor: "pointer",
											width: 180,
										}}
									>
										<Avatar sx={{ border: 1, borderColor: "#DBDBDB" }} alt="Travis Howard" src={user.avatar} />
										<Typography sx={{ ml: 2, fontSize: 14, fontWeight: 600, mt: 1, fontFamily: "arial" }} variant="h6" component="h6">
											{user.username}
										</Typography>
									</Box>
									{/* <Box sx={{ mt: -0.5, color: "#28A5F6" }}>
									<p style={{ fontSize: 14, fontWeight: 600, cursor: "pointer" }} onClick={() => handleFollow(user)}>
										Follow
									</p>
								</Box> */}
								</Box>
						  ))
						: followings.map((user, idx) => (
								<Box key={idx} sx={{ display: "flex", justifyContent: "space-between", pb: 2 }}>
									<Box
										onClick={() => {
											handleClose();
											navigate(`/profile/${user._id}`);
										}}
										sx={{
											display: "flex",
											justifyContent: "flex-start",
											cursor: "pointer",
											width: 180,
										}}
									>
										<Avatar sx={{ border: 1, borderColor: "#DBDBDB" }} alt="Travis Howard" src={user.avatar} />
										<Typography sx={{ ml: 2, fontSize: 14, fontWeight: 600, mt: 1, fontFamily: "arial" }} variant="h6" component="h6">
											{user.username}
										</Typography>
									</Box>
									{/* <Box sx={{ mt: -0.5, color: "#28A5F6" }}>
									<p style={{ fontSize: 14, fontWeight: 600, cursor: "pointer" }} onClick={() => handleFollow(user)}>
										Follow
									</p>
								</Box> */}
								</Box>
						  ))}
				</Box>
			</Modal>
		</>
	);
};

export default Followers;
