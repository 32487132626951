import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "./AddWallet.css";
import { useWeb3React } from "@web3-react/core";

import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { InjectedConnector } from "@web3-react/injected-connector";
import { io } from "socket.io-client";
import { Axios } from "../core/axios";
import Close from "@mui/icons-material/Close";
import ReactGA from "react-ga";
import { ethers } from "ethers";
import { isMobile } from "react-device-detect";

const CoinbaseWallet = new WalletLinkConnector({
	url: `https://mainnet.infura.io/v3/5099cc8c6ecb4719b7eede208d7fd408`,
	appName: "Gaggle Art",
	supportedChainIds: [1, 3, 4, 5, 42, 137],
	// urls: {
	// 	137: `https://polygon-mainnet.infura.io/v3/5099cc8c6ecb4719b7eede208d7fd408`,
	// 	1:
	// }
});

// const CoinbaseWalletPolygon = new WalletLinkConnector({
// 	// url: `https://polygon-mainnet.infura.io/v3/5099cc8c6ecb4719b7eede208d7fd408`,
// 	// appName: "Gaggle Art",
// 	// supportedChainIds: [1, 3, 4, 5, 42],
// 	urls: {
// 		137: `https://polygon-mainnet.infura.io/v3/5099cc8c6ecb4719b7eede208d7fd408`
// 	}
// });

const WalletConnect = new WalletConnectConnector({
	rpcUrl: `https://mainnet.infura.io/v3/5099cc8c6ecb4719b7eede208d7fd408`,
	bridge: "https://bridge.walletconnect.org",
	qrcode: true,
});

const Injected = new InjectedConnector({
	supportedChainIds: [1, 3, 4, 5, 42, 137],
});

const signMessage = async ({ setError, message }) => {
	try {
		console.log({ message });
		if (!window.ethereum) throw new Error("No crypto wallet found. Please install it.");

		await window.ethereum.request({ method: "eth_requestAccounts" });
		const provider = new ethers.providers.Web3Provider(window.ethereum);
		const signer = provider.getSigner();
		const signature = await signer.signMessage(message);
		const address = await signer.getAddress();

		return {
			message,
			signature,
			address,
		};
	} catch (err) {
		setError(err.message);
	}
};

function AddWallet({ name }) {
	// if (typeof window.ethereum !== "undefined") {
	// 	console.log("MetaMask is installed!");
	// }
	const socket = useRef();
	const { activate, deactivate, account, active, library } = useWeb3React();

	const [walletShowing, setWalletShowing] = useState(true);

	const [wallets, setWallets] = useState([]);
	const [walletString, setWalletString] = useState("");

	useEffect(() => {
		var _walletString = "";
		if (wallets.length > 0) {
			for (var i = 0; i < wallets.length; i++) {
				// if (i === wallets.length - 1) {
				// 	_walletString += wallets[i];
				// } else if (i === wallets.length - 2) {
				// 	_walletString += wallets[i] + " and ";
				// } else {
				// 	_walletString += wallets[i] + ", ";
				// }
				_walletString += wallets[i] + "\n";
			}
			setWalletString(_walletString);
		}
	}, [wallets]);

	useEffect(async () => {
		socket.current = io("https://api.gaggle.art");
	}, []);

	// Uncomment this to fetch the wallets from the server

	// useEffect(async () => {
	// 	const { data } = await Axios.get(`/users/get-wallet-addresss`);
	// 	setWallets(data);
	// }, [active]);

	useEffect(async () => {
		const user = localStorage.getItem("user");
		// var token = JSON.parse(user).jwt;
		if (user) {
			window.location.replace("/");
		}
		if (!account) {
			return;
		}
		console.log(account);
		console.log(library.provider.networkVersion);
		var walletType = "Ethereum";
		if (library.provider.networkVersion === "137") {
			walletType = "Polygon";
		}
		// Axios.defaults.baseURL = "http://localhost:3001/api/v1";
		const { data } = await Axios.post("/auth/getSignString", {
			address: account,
		});

		console.log(data);

		// var response = await signMessage({
		// 	setError: (err) => {
		// 		console.log(err);
		// 	},
		// 	message: data.sign,
		// });

		// console.log(response);
		var response = {};
		if (!library) return;
		try {
			const signature = await library.provider.request({
				method: "personal_sign",
				params: [data.sign, account],
			});
			// setSignedMessage(message);
			// setSignature(signature);
			response = {
				message: data.sign,
				signature,
				address: account,
				walletType,
			};
			console.log(response);
		} catch (error) {
			// setError(error);
			console.log(error);
		}

		const verifySign = await Axios.post("/auth/verifySignText", response);
		// console.log(verifySign);
		// console.log(verifySign.data.data);
		if (verifySign.data.data) {
			try {
				localStorage.setItem("user", JSON.stringify(verifySign.data.data));
				Axios.defaults.headers.common["Authorization"] = `Bearer ${verifySign.data.data.jwt}`;
				const { data } = await Axios.post("/assets/synchronization");
				window.location.href = "/profile";
			} catch (err) {
				console.log(err);
			}
			// const { new_data } = Axios.post("/assets/synchronization");
		} else {
			alert("Invalid signature");
		}
		// Uncomment this to add the wallets to the server

		// socket.current.emit("addwallet", {
		// 	walletAddress: account,
		// 	token,
		// });
		// // console.log("added wallet");
		// socket.current.on("walledAdded", (data) => {
		// 	console.log("wallet added");
		// 	console.log(data);
		// 	if (data.isAdded) {
		// 		console.log("Wallet added successfully");
		// 		const { new_data } = Axios.post("/assets/synchronization");
		// 		// window.location.href = "/profile";
		// 		if (!wallets.includes(data.walletAddress)) {
		// 			setWallets([...wallets, data.walletAddress]);
		// 		}
		// 	}
		// });
	}, [active]);

	useEffect(() => {
		ReactGA.pageview(window.location.pathname);
	}, []);

	return (
		<div className="bg-secondary-background min-h-screen dark:bg-primary-background">
			<div className="flex flex-col items-center h-screen">
				{wallets.length > 0 && walletShowing && (
					<div
						className="bg-white rounded-lg shadow-lg p-8"
						style={{
							marginTop: "5%",
							position: "relative",
						}}
					>
						<div className="text-center mb-4">
							<h3 className="font-bold add_wallet_text">Linked Wallets</h3>
						</div>
						<div className="text-center">
							{wallets.map((wallet, i) => (
								<div className="wallet_text" key={i}>
									{wallet}
								</div>
							))}
						</div>
						{/* Remove All Wallets */}
						<div className="flex justify-center mt-5">
							<button
								className="bg-white hover:bg-gray-100 text-gray-800 font-bold py-2 px-4 border border-gray-400 rounded shadow"
								type="button"
								onClick={async () => {
									const { data } = await Axios.post("/users/remove-all-wallet-address");
									console.log(data);
									if (data.walletAddress.length == 0) {
										const { new_data } = Axios.delete("/assets/own");
										setWallets([]);
									}
								}}
							>
								Remove All Wallets
							</button>
						</div>

						{/* Add A cross icon on top right corner of parent div */}
						<div
							style={{
								position: "absolute",
								top: "0",
								right: "0",
							}}
						>
							<Close
								onClick={() => {
									setWalletShowing(false);
								}}
								sx={{
									fontSize: 35,
									color: "black",
									cursor: "pointer",
									m: 0.5,
								}}
							></Close>
						</div>
					</div>
				)}
				<div
					className="text-black text-center dark:text-white"
					style={{
						marginTop: "5%",
					}}
				>
					<h1
						className="text-lg font-bold"
						style={{
							fontSize: "1.5rem",
							marginBottom: "1rem",
						}}
					>
						{wallets.length > 0 ? "Connect more wallets" : "Connect your Wallets"}
					</h1>
				</div>
				<div className="text-black text-center dark:text-white">
					<h1
						className="text-sm font-semibold"
						style={{
							fontSize: "1.5rem",
							padding: "1rem",
							border: "1px solid #F99D5E",
							borderRadius: "5px",
							margin: "1rem",
							width: "20rem",
							flexDirection: "row",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							cursor: "pointer",
						}}
						onClick={async () => {
							// console.log(window.ethereum.isMetaMask);
							activateInjectedProvider("MetaMask");
							activate(Injected);
							if (isMobile) {
								if (window.ethereum) {
								} else {
									window.location.href = "https://metamask.app.link/dapp/gaggle.art/addwallet";
								}
							}
						}}
					>
						<img
							src="/icons/metamask.svg"
							style={{
								width: "2.0rem",
								height: "2.0rem",
								marginRight: "0.5rem",
							}}
						/>{" "}
						<h2>Meta Mask</h2>
					</h1>
					<h1
						className="text-sm font-semibold"
						style={{
							fontSize: "1.5rem",
							padding: "1rem",
							border: "1px solid #F99D5E",
							borderRadius: "5px",
							margin: "1rem",
							width: "20rem",
							flexDirection: "row",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							cursor: "pointer",
						}}
						onClick={() => {
							activateInjectedProvider("CoinBase");
							activate(CoinbaseWallet);
						}}
					>
						<img
							src="/icons/coinbase.svg"
							style={{
								width: "2.0rem",
								height: "2.0rem",
								marginRight: "0.5rem",
							}}
						/>{" "}
						Coinbase
					</h1>
					<h1
						className="text-sm font-semibold"
						style={{
							fontSize: "1.5rem",
							padding: "1rem",
							border: "1px solid #F99D5E",
							borderRadius: "5px",
							margin: "1rem",
							width: "20rem",
							flexDirection: "row",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							cursor: "pointer",
						}}
						onClick={() => {
							// activateInjectedProvider("CoinBase");
							activate(WalletConnect);
							// window.location.href = "gaggle://nifty-backend-server.herokuapp.com/home?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2MjdiNTE5NTNlOTAwN2JhNDZiOTU2ZGMiLCJpYXQiOjE2NTI0NDczMjd9.0-fm74xev26-XhMUzhY6I0uF9CQUcAQAf5WHG70xVTQ";
						}}
					>
						<img
							src="/icons/walletconnect-logo.svg"
							style={{
								width: "2.0rem",
								height: "2.0rem",
								marginRight: "0.5rem",
							}}
						/>{" "}
						WalletConnect
					</h1>
				</div>

				<div
					style={{
						marginTop: "0.8rem",
						width: isMobile ? "auto" : "25rem",
					}}
				>
					<h1
						className="text-[#000] dark:text-[#fff]"
						style={{
							textAlign: "center",
							fontSize: "1.1rem",
						}}
					>
						Get the app.
					</h1>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							marginTop: "0.8rem",
						}}
					>
						<div
							style={{
								alignItems: "center",
								width: "50%",
							}}
						>
							<img
								style={{
									alignSelf: "center",
									width: "100%",
									cursor: "pointer",
								}}
								onClick={() => {
									window.open("https://apps.apple.com/us/app/gaggle-art/id1629844116", "_blank");
								}}
								src="/icons/appstore.svg"
							/>
						</div>
						<div
							style={{
								alignItems: "center",
								width: "50%",
							}}
						>
							<img
								style={{
									alignSelf: "center",
									width: "100%",
									cursor: "pointer",
								}}
								onClick={() => {
									window.open("https://play.google.com/store/apps/details?id=com.gaggleart", "_blank");
								}}
								src="/icons/google-play-badge.png"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export function activateInjectedProvider(providerName) {
	const { ethereum } = window;
	console.log(ethereum);
	console.log(window);
	if (!ethereum?.providers) {
		return undefined;
	}

	let provider;
	switch (providerName) {
		case "CoinBase":
			provider = ethereum.providers.find(({ isCoinbaseWallet }) => isCoinbaseWallet);
			break;
		case "MetaMask":
			provider = ethereum.providers.find(({ isMetaMask }) => isMetaMask);
			break;
	}

	if (provider) {
		ethereum.setSelectedProvider(provider);
	}
}

export default AddWallet;
