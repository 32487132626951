import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import PostCard from "../componnent/Cards/PostCard";
import { Axios } from "../core/axios";
import { LinearProgress, Button, Box } from "@mui/material";
import Modal from "../componnent/Modal";
import ReactGA from "react-ga";

function Search({ globalLoading }) {
	const [params] = useSearchParams();
	const [searchText, setSearchText] = useState(params.get("search") || "");

	const handleSearch = (e) => {
		if (e.keyCode === 13 && searchText !== "") {
			window.location.href = `/?search=${searchText}`;
		}
	};

	const Search = styled("div")(({ theme }) => ({
		position: "relative",
		borderRadius: theme.shape.borderRadius,
		backgroundColor: alpha(theme.palette.common.white, 0.15),
		"&:hover": {
			backgroundColor: alpha(theme.palette.common.white, 0.25),
		},
		marginRight: theme.spacing(2),
		marginLeft: 0,
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			marginLeft: theme.spacing(3),
			width: "auto",
		},
	}));

	const [posts, setPosts] = useState([]);
	const [isLoadable, setLoadable] = useState(false);
	const [isLoading, setLoading] = useState(true);

	const loadPosts = async () => {
		try {
			const query = params.get("search");
			const skip = posts.length;

			setLoading(true);
			let url = `/assets/feed?skip=${skip}`;
			if (query) {
				url = `/assets/?query=${query}&skip=${skip}`;
			}

			const { data } = await Axios.get(url);

			setLoading(false);

			setLoadable(data.data.length === 21);

			setPosts([...posts, ...data.data]);
		} catch (error) {
			setLoading(false);
			alert(error.message);
		}
	};

	useEffect(() => {
		loadPosts();
	}, []);

	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);

	return (
		<div className="bg-secondary-background min-h-screen dark:bg-primary-background">
			<div className="bg-white p-4 dark:bg-black">
				<div className="bg-primary-background flex items-center px-4 py-2 rounded-md">
					<img src="/icons/searchWhite.svg" alt="" className="rotate-180 h-3" />
					<input type="text" value={searchText} onChange={(e) => setSearchText(e.target.value)} onKeyDown={handleSearch} placeholder="Search" className="bg-primary-background w-full px-2 py-1 text-sm outline-none text-white" />
				</div>
			</div>
			{/* <div className="grid grid-cols-4 gap-2 p-4">
				{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
					<div>
						<img src="/images/nft2.png" className="h-16 rounded-lg" />
					</div>
				))}
			</div> */}
			{(isLoading || globalLoading) && (
				<div
					style={{
						position: "fixed",
						width: "100%",
						top: 0,
						left: 0,
						right: 0,
						zIndex: 99999,
					}}
				>
					<LinearProgress color="error" />
				</div>
			)}
			<div className="px-4 sm:px-10 py-4 space-y-4 sm:space-y-0 gap-4 w-full  grid grid-cols-1 md:grid-cols-3  mx-auto">
				{posts.length === 0 && !isLoading ? (
					<div>
						<h1>Nothing to display.</h1>
					</div>
				) : (
					posts.map((post, idx) => <PostCard nft={post} key={idx} />)
				)}
			</div>
			{isLoadable && (
				<h1 className="text-white cursor-pointer px-4 text-md text-bold pb-10" onClick={loadPosts}>
					{isLoading ? "Loading..." : "Load More"}
				</h1>
			)}
		</div>
	);
}

export default Search;
