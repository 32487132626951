import React from "react";

function SalesCard({ title, img, salesValue, salesPercent, aurValue, aurPercent, isUpAverage, isUpSale }) {
	return (
		<>
			<div className=" flex-shrink-0 h-12 px-2 min-w-[300px] rounded-lg bg-white drop-shadow-md flex space-x-2 items-center dark:bg-black">
				<img src={img} className="h-8 w-8 rounded-full bg-orange-600"></img>
				<div className="flex flex-col ">
					<h1 className="text-black text-xs font-bold dark:text-white">{title}</h1>
					<div className="flex space-x-2">
						<div className="flex">
							<h1 className="text-[12px] text-black dark:text-white">{`Sales: ${salesValue}`}</h1>
							<h2 className={`text-[12px] font-bold ${isUpSale ? "text-red-900" : "text-green-900"} `}>{`(${salesPercent})`}</h2>
						</div>
						<div className="flex">
							<h1 className="text-[12px] text-black dark:text-white">{`AUR: ${aurValue}`}</h1>
							<h2 className={`text-[12px] font-bold ${isUpAverage ? "text-red-900" : "text-green-900"}`}>{`(${aurPercent})`}</h2>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default SalesCard;
