import React, { useState, useRef, useEffect } from "react";
import { Avatar, Box, Button, TextField, LinearProgress, Grid, Container } from "@mui/material";
import { Axios } from "../core/axios";
import ReactGA from "react-ga";

function EditProfile() {
	const imgRef = useRef();
	const user = JSON.parse(localStorage.getItem("user"));

	const [isLoading, setLoading] = useState(false);
	// const [fName, setFname] = useState(user.firstName);
	// const [lName, setLname] = useState(user.lastName);

	const [username, setUsername] = useState(user.username);

	const [bio, setBio] = useState(user.bio);
	const [email, setEmail] = useState(user.email);
	const [avatarUrl, setAvatarUrl] = useState(user.avatar);
	const [avatar, setAvatar] = useState(user.avatar);
	const [walletAddress, setWalletAddress] = useState(user.walletAddress ? user.walletAddress[0] : "");

	const deleteAllNft = async () => {
		const data = await Axios.delete("/assets/own");
		console.log(data.data.message);
		alert(data.data.message);
		window.location.href = "/profile";
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (username === "") return;

		setLoading(true);
		const formData = new FormData();

		if (avatar) {
			formData.append("avatar", avatar);
		}

		// formData.append("firstName", fName);
		// formData.append("lastName", lName);
		formData.append("username", username);
		formData.append("bio", bio);
		formData.append("email", email);

		try {
			const { data } = await Axios.put("/users/update", formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});

			localStorage.setItem("user", JSON.stringify({ ...user, ...data.user }));

			window.location.href = "/profile";
		} catch (error) {
			alert(error.message);
			setLoading(false);
		}
	};

	const updateAvatar = async (e) => {
		const file = e.target.files[0];

		const reader = new FileReader();

		reader.readAsDataURL(file);

		reader.onload = () => {
			if (reader.readyState === 2) {
				setAvatar(file);
				setAvatarUrl(reader.result);
			}
		};
	};

	useEffect(() => {
		ReactGA.pageview(window.location.pathname);
	}, []);

	return (
		<div className="bg-secondary-background min-h-screen pb-10 text-black dark:bg-primary-background dark:text-white">
			{isLoading && (
				<div
					style={{
						position: "fixed",
						width: "100%",
						top: 0,
						left: 0,
						right: 0,
						zIndex: 99999,
					}}
				>
					<LinearProgress color="error" />
				</div>
			)}
			<input type="file" onChange={updateAvatar} ref={imgRef} className="hidden" id="image" />
			<div className="flex justify-center items-center p-4">
				<div className="h-20 w-20 relative ">
					<img src={avatarUrl} alt="" className="rounded-full bg-cover h-full w-full " />
					<img src="/icons/addPic.svg" alt="" onClick={() => document.getElementById("image").click()} className="absolute bottom-0 -right-3" />
				</div>
			</div>
			<div className="h-[2px] w-full bg-gray-900"></div>
			<div className=" p-4 text-xs gap-2 sm:w-[400px] mx-auto">
				<div className="grid grid-cols-3 ">
					<h1>Username</h1>
					<div className=" col-span-2">
						<input type="text" placeholder="" onChange={(e) => setUsername(e.target.value)} value={username} className="pl-4 w-full outline-none bg-transparent" />
						<div className="h-[2px] w-full bg-gray-900 m-2"></div>
					</div>
				</div>
				{/* <div className="grid grid-cols-3 ">
					<h1>Last Name</h1>
					<div className=" col-span-2">
						<input type="text" placeholder="" value={lName} onChange={(e) => setLname(e.target.value)} className="pl-4 w-full outline-none bg-transparent" />
						<div className="h-[2px] w-full bg-gray-900 m-2"></div>
					</div>
				</div> */}

				{/* <div className="grid grid-cols-3 ">
					<h1>UserName</h1>
					<div className=" col-span-2">
						<input type="text" placeholder="" className="pl-4 w-full outline-none bg-transparent" />
						<div className="h-[2px] w-full bg-gray-900 m-2"></div>
					</div>{" "}
				</div> */}
				<div className="grid grid-cols-3 ">
					<h1>Bio</h1>
					<div className=" col-span-2">
						<textarea type="text" value={bio} onChange={(e) => setBio(e.target.value)} placeholder="" className="pl-4 w-full outline-none bg-transparent" />
						<div className="h-[2px] w-full bg-gray-900 m-2"></div>
					</div>{" "}
				</div>
				{/* <div className="grid grid-cols-3 ">
					<h1>Website</h1>
					<div className=" col-span-2">
						<input type="text" placeholder="" className="pl-4 w-full outline-none bg-transparent" />
						<div className="h-[2px] w-full bg-gray-900 m-2"></div>
					</div>{" "}
				</div> */}

				<div className="grid grid-cols-3 ">
					<h1>Wallet Address</h1>
					<div className=" col-span-2">
						<textarea disabled type="text" value={walletAddress} placeholder="" className="pl-4 w-full outline-none bg-transparent" />
						<div className="h-[2px] w-full bg-gray-900 m-2"></div>
					</div>{" "}
				</div>
			</div>
			<div className="h-[2px] w-full bg-gray-900"></div>

			<div className=" p-4 text-xs gap-2 sm:w-[400px] mx-auto">
				<div className="grid grid-cols-3 ">
					<h1>Email</h1>
					<div className=" col-span-2">
						<input type="text" placeholder="" value={email} onChange={(e) => setEmail(e.target.value)} className="pl-4 w-full outline-none bg-transparent" />
						<div className="h-[2px] w-full bg-gray-900 m-2"></div>
					</div>
				</div>
				{/* <div className="grid grid-cols-3 ">
					<h1>Password</h1>
					<div className=" col-span-2">
						<input type="text" placeholder="*******" className="pl-4 w-full outline-none bg-transparent" />
						<div className="h-[2px] w-full bg-gray-900 m-2"></div>
					</div>{" "}
				</div>

				<div className="grid grid-cols-3 ">
					<h1>Verify Password</h1>
					<div className=" col-span-2">
						<input type="text" placeholder="*******" className="pl-4 w-full outline-none bg-transparent" />
						<div className="h-[2px] w-full bg-gray-900 m-2"></div>
					</div>{" "}
				</div> */}
			</div>
			<div onClick={handleSubmit} className="rounded-lg bg-[#F99D5E] m-4 shadow-md shadow-black cursor-pointer py-2 sm:w-[400px] mx-auto">
				<h1 className="text-xs text-white flex justify-center font-bold">Save</h1>
			</div>
			<div onClick={deleteAllNft} className="rounded-lg bg-[#000000] m-4 shadow-md shadow-black cursor-pointer py-2 sm:w-[400px] mx-auto">
				<h1 className="text-xs text-white flex justify-center font-bold">Remove All NFTs</h1>
			</div>
		</div>
	);
}

export default EditProfile;
