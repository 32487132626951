import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Home from "./componnent/Home/Home";
import Home from "./pages/Home";
import Navigation from "./componnent/Shared/Navigation/Navigation";
import Profile from "./pages/Profile";
import Settings from "./pages/Settings";
import AuthProvider from "./contexts/AuthContext";
import Login from "./pages/Login";
import Header from "./componnent/Header";
import Navbar from "./componnent/Navbar";
import MyProfile from "./pages/MyProfile";
import Search from "./pages/Search";
import Saved from "./pages/Saved";
import EditProfile from "./pages/EditProfile";
import Notifications from "./pages/Notifications";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Messenger from "./pages/Messenger";
// import Messenger from "./componnent/messenger/Messenger";
import Modal from "./componnent/Modal";
import { useEffect, useState } from "react";
import { Axios } from "./core/axios";
import AddWallet from "./pages/AddWallet";
import TermsAndCondition from "./pages/TermsAndCondition";
import Discord from "./pages/Discord";

import ReactGA from "react-ga";
import AddWalletApp from "./pages/AddWalletApp";
import AddWalletAppAndroid from "./pages/AddWalletAppAndroid";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import AddWalletAppNotDeepLink from "./pages/AddWalletAppNotDeepLink";
import WalledLinked from "./pages/WalledLinked";
const TRACKING_ID = "UA-226156510-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
	const [modal, setModal] = useState(true);
	const [isVerified, setIsVerified] = useState(false);
	const [password, setPassword] = useState();
	const [globalLoading, setGlobalLoading] = useState(false);
	const onSubmit = async () => {
		try {
			let url = `/auth/check-site-open-password`;
			const { data } = await Axios.post(url, {
				password,
			});
			if (data.isValid) {
				localStorage.setItem("isVerifiedToUse", true);
				setIsVerified(true);
				setModal(false);
			} else {
				alert("Password is Wrong Try Again!!");
			}
		} catch (err) {
			alert(err.message);
		}
	};
	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);
	return (
		<AuthProvider>
			<BrowserRouter sx={{ backgroundColor: "#FAFAFA" }}>
				{/* {!localStorage.getItem("isVerifiedToUse") && modal && (
					<Modal title={"hii"} onClose={() => {}}>
						<div className="flex w-full justify-center items-center flex-col space-y-2">
							<h1 className="text-gray-100 text-xl mb-10 font-bold">Enter Password to Continue</h1>
							<input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} className="outline-none bg-transparent w-full rounded-lg px-4 py-2 text-white border" />
							<div onClick={onSubmit} className="bg-[#F99D5E] shadow-sm w-full flex justify-center py-2 rounded-lg cursor-pointer shadow-black">
								<h1 className="text-white font-bold ">Submit</h1>
							</div>
						</div>
					</Modal>
				)} */}
				{/* <Navigation /> */}
				{<Header setGlobalLoading={setGlobalLoading} globalLoading={globalLoading} />}
				{/* {location.pathname !== "/login" && <Header />} */}
				<Routes>
					<Route index element={<Home globalLoading={globalLoading} />} />
					<Route path="/home" index element={<Home globalLoading={globalLoading} />} />
					<Route path="/profile" element={<Profile setGlobalLoading={setGlobalLoading} globalLoading={globalLoading} />} />
					<Route path="/myprofile" element={<MyProfile />} />
					<Route path="/editProfile" element={<EditProfile />} />
					<Route path="/search" element={<Search globalLoading={globalLoading} />} />
					<Route path="/saved" element={<Saved globalLoading={globalLoading} />} />
					<Route path="/profile/:id" element={<Profile />} />
					<Route path="/settings" element={<Settings />} />
					<Route path="/login" element={<Login />} />
					<Route path="/register" element={<Register />} />
					<Route path="/forgotPassword" element={<ForgotPassword />} />
					<Route path="/password-reset/:token" element={<ResetPassword />} />
					<Route path="/notifications" element={<Notifications />} />
					<Route path="/messenger" element={<Messenger />} />
					<Route path="/addwallet" element={<AddWallet />} />
					<Route path="/messenger/:id" element={<Messenger />} />
					<Route path="/terms" element={<TermsAndCondition />} />
					<Route path="/policy" element={<PrivacyPolicy />} />
					<Route path="/discord_chat" element={<Discord />} />

					<Route path="/add_wallet_app" element={<AddWalletApp />} />
					<Route path="/add_wallet_app_android" element={<AddWalletAppAndroid />} />

					<Route path="/add_wallet_app_not_deep/:id" element={<AddWalletAppNotDeepLink />} />
					<Route path="/connected" element={<WalledLinked />} />
				</Routes>
				<Navbar setGlobalLoading={setGlobalLoading} globalLoading={globalLoading} />
				{/* {location.pathname !== "/login" && <Navbar />} */}
			</BrowserRouter>
		</AuthProvider>
	);
}

export default App;
