import React, { useState } from "react";
import { Axios } from "../../core/axios";

function RequestCards({ sentBy, alsoFollowedBy, img, isFollowedBack, followedBy }) {
	const [followBack, setFollowBack] = useState(isFollowedBack);
	const handleFollow = async () => {
		try {
			if (!followBack) {
				await Axios.post(`profile/${followedBy._id}/follow`, {
					operationFor: "follow",
				});
			} else {
				await Axios.post(`profile/${followedBy._id}/follow`, {
					operationFor: "unfollow",
				});
			}

			setFollowBack(!followBack);
		} catch (error) {
			alert(error.message);
		}
	};
	return (
		<div>
			<div className="flex mt-2 space-x-3">
				<img
					src={img}
					alt=""
					className="rounded-full h-12 w-12 cursor-pointer"
					onClick={() => {
						window.location.href = `/profile/${followedBy._id}`;
					}}
				/>
				<div className="text-black text-xs dark:text-white">
					<div className="">
						<h1 className="font-bold">
							{sentBy}
							<span className="text-xs">{", has requested to follow you."}</span>
						</h1>
					</div>
					{/* <div className="flex space-x-1">
						<h2 className="">{alsoFollowedBy} </h2>
						<h1 className="font-bold">{"Dave Richrd"}</h1>
					</div> */}
					<div
						onClick={() => {
							// setFollowBack(!followBack);
							handleFollow();
						}}
						className={` ${!followBack ? " bg-[#F99D5E]" : "border border-[#F99D5E]"}  cursor-pointer flex justify-center items-center  py-1 rounded-lg mt-1`}
					>
						<h1 className="text-white">{`${!followBack ? "Follow Back" : "Following"}`}</h1>
					</div>
				</div>
			</div>
		</div>
	);
}

export default RequestCards;
