import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SettingsCard from "../componnent/Cards/SettingsCard";
import ReactGA from "react-ga";

function Settings() {
	const handleLogout = () => {
		localStorage.removeItem("user");
		window.location.href = "/";
	};

	useEffect(() => {
		ReactGA.pageview(window.location.pathname);
	}, []);

	return (
		<div className="bg-secondary-background min-h-screen flecx flex-col justify-between dark:bg-primary-background">
			<div>
				<div className="p-4 text-black text-xs font-bold dark:text-white">
					<h1>Settings</h1>
				</div>
				<div className="grid grid-cols-2 px-4 gap-2 text-white sm:w-[700px] mx-auto">
					<SettingsCard img={"/icons/bell.svg"} title="Notifications" />
					<Link to={"/terms"}>
						<SettingsCard img={"/icons/lock-alt.svg"} title="T & Cs" onClick />
					</Link>
					<SettingsCard img={"/icons/shield-check.svg"} title="Security" />
					<SettingsCard img={"/icons/tags.svg"} title="Ads" />
					<SettingsCard img={"/icons/comment-alt-smile.svg"} title="Help" />
					<SettingsCard img={"/icons/info-circle.svg"} title="About" />
					<h1 className="bg-black rounded-lg space-y-2 flex flex-col items-center justify-center p-4 cursor-pointer" onClick={handleLogout}>
						Log Out
					</h1>
				</div>
			</div>
			<div className="absolute bottom-0 w-full">
				<div className="h-[2px] w-full bg-gray-400 dark:bg-gray-900"></div>
				<div className="text-xs text-black font-bold p-4 dark:text-white">
					<h1 style={{ fontSize: 12 }}>Profile Settings</h1>
					<h1 style={{ fontSize: 12, marginTop: 10 }}>Switch Accounts</h1>
				</div>
			</div>
		</div>
	);
}

export default Settings;
