import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextField, CircularProgress } from "@mui/material";
// import Registration from "./Registration/Registration";
import { Axios } from "../core/axios";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";

function ForgotPassword({ handleClose, open }) {
	const [openr, setOpenr] = useState(false);
	const handleOpenr = () => setOpenr(true);
	const handleCloser = () => setOpenr(false);
	const [email, setEmail] = useState("");
	// const [password, setPassword] = useState("");
	const [isLoading, setLoading] = useState(false);

	const handleForgot = async (e) => {
		console.log("kjsdk");
		e.preventDefault();

		if (email === "") return;

		try {
			setLoading(true);
			const { data } = await Axios.post("/auth/reset-password", { email });

			localStorage.setItem("user", JSON.stringify(data));
			alert("Password reset link has been sent to your registered email 😎");
			// window.location.href = "/profile";
		} catch (error) {
			alert(error.message);
			setLoading(false);
		}
	};

	useEffect(() => {
		ReactGA.pageview(window.location.pathname);
	}, []);

	return (
		<div className="bg-black text-white min-h-screen flex flex-col items-center justify-center">
			<div className="h-full flex w-full flex-col items-center">
				<img src="/icons/logoSkull.svg" alt="" className="h-12 w-12" />
				<div className="w-full p-8 sm:w-[500px] mx-auto">
					<h1 className="text-2xl mb-8 font-bold">Forgot Password</h1>
					<input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} className="bg-transparent border-b pb-2 mb-4 border-gray-500 outline-none text-xs w-full" />
					{/* <div className="flex border-gray-500 border-b pb-2 items-end mb-8">
						<input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} className="bg-transparent  outline-none  text-xs w-full" />
						<img src="/icons/eye.svg" alt="" className="pb-2" />
					</div> */}
					<div onClick={handleForgot} className="py-3 cursor-pointer bg-[#F99D5E] flex justify-center rounded-lg">
						<h1 className="text-white font-bold text-xs">CONTINUE</h1>
					</div>
					<Link to={"/login"} className="py-3 cursor-pointer  mt-4 shadow-md shadow-black flex justify-center rounded-lg">
						<h1 className="text-white font-bold text-xs">Sign In</h1>
					</Link>
					<Link to={"/register"} className="py-3 cursor-pointer bg mt-4 shadow-md shadow-black flex justify-center rounded-lg">
						<h1 className="text-white font-bold text-xs">Sign Up</h1>
					</Link>
				</div>
				<img src="/icons/gaggle_icon.svg" alt="" className="h-6 fixed bottom-8" />
			</div>
		</div>
	);
}

export default ForgotPassword;
