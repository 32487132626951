import React from "react";

function SettingsCard({ title, img }) {
	return (
		<div>
			<div className="bg-black rounded-lg space-y-2 flex flex-col items-center justify-center p-4">
				<img src={img} alt="" />
				<h1>{title}</h1>
			</div>
		</div>
	);
}

export default SettingsCard;
