import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";

function MyProfile({ name }) {
	useEffect(() => {
		ReactGA.pageview(window.location.pathname);
	}, []);
	return (
		<div className="bg-primary-background min-h-screen">
			<div className="p-4">
				<div className="flex space-x-2 items-center justify-center">
					<div className="rounded-full h-12 w-12">
						<img src="/images/nft1.png" alt="" className="rounded-full" />
					</div>
					<div className="text-white space-y-1">
						<h1 className="flex flex-col text-sm font-bold">{"John Smith"}</h1>
						<h2 className="flex flex-col text-xs">{"A Showcase of NFTs from leading creators and brands around the world"}</h2>
					</div>
				</div>
				<div className="text-sm mt-4">
					<Link to={"/editProfile"} className="text-white font-bold cursor-pointer border border-[#F99D5E] py-1 flex justify-center rounded-lg">
						Edit Profile
					</Link>
				</div>
			</div>
			<div className="h-[2px] w-full bg-gray-900"></div>
			<div className="p-4">
				<div className="grid grid-cols-3">
					<div className="text-white flex flex-col items-center justify-center">
						<h1 className="text-lg font-bold">120</h1>
						<h1 className="text-md font-semibold">NFTs</h1>
					</div>
					<div className="text-white flex flex-col items-center justify-center">
						<h1 className="text-lg font-bold">7,023</h1>
						<h1 className="text-md font-semibold">Followers</h1>
					</div>
					<div className="text-white flex flex-col items-center justify-center">
						<h1 className="text-lg font-bold">10,000</h1>
						<h1 className="text-md font-semibold">Following</h1>
					</div>
				</div>
				<div className="grid text-sm grid-cols-2 gap-4 mt-4">
					<div className="bg-[#F99D5E] cursor-pointer py-1 w-full rounded-md shadow-lg shadow-black item-center space-x-1  flex justify-center">
						<img src="/icons/display.svg" alt="" className="h-4 my-auto" />
						<h1 className="text-white font-bold">Display Mode</h1>
					</div>
					<div className="bg-black py-1 w-full cursor-pointer rounded-md shadow-lg shadow-black flex item-center justify-center">
						<h1 className="text-white font-bold">Select</h1>
					</div>
				</div>
			</div>
			<div className="h-[2px] w-full bg-gray-900"></div>
			<div className="grid grid-cols-4 gap-2 p-4">
				{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
					<div>
						<img src="/images/nft1.png" className="h-16 rounded-lg" />
					</div>
				))}
			</div>
		</div>
	);
}

export default MyProfile;
