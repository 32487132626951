import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { utils } from "ethers";
import { Axios } from "../core/axios";

function Navbar({ globalLoading, setGlobalLoading }) {
	const navigate = useNavigate();
	const { user } = useAuth();
	const [isLoading, setLoading] = useState(true);
	const location = useLocation();
	console.log(location);

	const addWalletAddress = async () => {
		if (user) {
			try {
				const walletAddress = prompt("Enter your wallet address...");

				if (utils.isAddress(walletAddress)) {
					setLoading(true);

					await Axios.post("/users/add-wallet-address", {
						walletAddress,
					});

					const { data } = await Axios.post("/assets/synchronization");

					setLoading(false);
					// alert(data.message);

					const user = JSON.parse(localStorage.getItem("user"));
					user.walletAddress = walletAddress;

					localStorage.setItem("user", JSON.stringify(user));
					window.location.href = "/profile";
				} else {
					setLoading(false);
					alert("Invalid wallet address.");
				}
			} catch (error) {
				setLoading(false);
				alert(error.message);
			}
		} else {
			navigate("/login");
		}
	};

	return (
		<div>
			{location.pathname !== "/login" && location.pathname !== "/password-reset" && location.pathname !== "/register" && location.pathname !== "/forgotPassword" && (
				<div className="relative">
					<div className="h-12 bg-black grid grid-cols-5 w-full fixed bottom-0 left-0 right-0">
						<Link to={"/"} title="Home Screen" className="my-auto">
							<img src={`${location.pathname === "/" ? "/icons/homeRed.svg" : "/icons/homeWhite.svg"}`} alt="" className="flex justify-center items-center w-full rotate-180 h-6" />
						</Link>
						<Link to={"/search"} title="Search any NFTs" className="my-auto">
							<img src={`${location.pathname === "/search" ? "/icons/searchRed.svg" : "/icons/searchWhite.svg"}`} alt="" className="flex justify-center items-center w-full rotate-180 h-6" />
						</Link>
						<div
							onClick={async () => {
								if (user) {
									setGlobalLoading(true);
									const { data } = await Axios.post("/assets/synchronization");

									setGlobalLoading(false);
								} else {
									navigate("/addwallet");
								}
							}}
							title={user ? "Sync NFTs" : "Link a Wallet"}
							className="relative flex justify-center"
						>
							<img src={user ? "/icons/wallet_linked.svg" : "/icons/credit.svg"} alt="" className="flex  justify-center items-center absolute bottom-6 border my-auto rotate-180 h-12 w-12 bg-primary-background rounded-full p-2" />
						</div>
						<Link to={"/saved"} title="View saved NFTs" className="my-auto">
							<img src={`${location.pathname === "/saved" ? "/icons/heartRed.svg" : "/icons/heartWhite.svg"}`} alt="" className="flex justify-center items-center w-full h-6" />
						</Link>
						<Link to={`${user ? "/profile" : "/addwallet"}`} title="View your Profile" className="my-auto">
							<img src={`${location.pathname === "/myprofile" ? "/icons/personRed.svg" : "/icons/personWhite.svg"}`} alt="" className="flex justify-center items-center w-full rotate-180 h-6" />
						</Link>
					</div>
				</div>
			)}
		</div>
	);
}

export default Navbar;
