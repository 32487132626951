import React, { useEffect, useState } from "react";
import ActivityCard from "../componnent/Cards/ActivityCard";
import RequestCards from "../componnent/Cards/RequestCards";
import { useSearchParams } from "react-router-dom";
import { Axios } from "../core/axios";
import { LinearProgress, Button, Box } from "@mui/material";
import ReactGA from "react-ga";

function Notifications() {
	const [params] = useSearchParams();

	const [followRequests, setFollowRequests] = useState([]);
	const [notifications, setNotifications] = useState([]);
	// const [isLoadable, setLoadable] = useState(false);
	const [isLoading, setLoading] = useState(true);

	const loadFollowRequests = async () => {
		try {
			// const query = params.get("search");
			// const skip = posts.length;

			setLoading(true);
			let url = `/follow-notifications`;

			const { data } = await Axios.get(url);
			// console.log(data);

			setLoading(false);

			// setLoadable(data.data.length === 20);

			console.log(data);

			setFollowRequests([...followRequests, ...data.followerNotification]);
		} catch (error) {
			setLoading(false);
			alert(error.message);
		}
	};

	const loadNotifications = async () => {
		try {
			// const query = params.get("search");
			// const skip = posts.length;

			setLoading(true);
			let url = `/notifications`;

			const { data } = await Axios.get(url);
			// console.log(data);

			setLoading(false);

			// setLoadable(data.data.length === 20);

			console.log(data);

			setNotifications([...notifications, ...data.notifications]);
		} catch (error) {
			setLoading(false);
			alert(error.message);
		}
	};

	useEffect(() => {
		loadFollowRequests();
		loadNotifications();
	}, []);

	useEffect(() => {
		ReactGA.pageview(window.location.pathname);
	}, []);

	return (
		<div className="bg-secondary-background min-h-screen dark:bg-primary-background">
			<div className="p-4">
				<h1 className="text-black font-bold text-xs dark:text-white">Follow Requests</h1>
				{followRequests.length !== 0 && followRequests.map(({ followedBy, isFollowedBack }, index) => <RequestCards key={index} sentBy={followedBy.username} alsoFollowedBy="John Mayer" isFollowedBack={isFollowedBack} img={followedBy.avatar} followedBy={followedBy} />)}
			</div>
			<div className="h-[2px] w-full bg-gray-400 dark:bg-gray-900"></div>
			<div className="p-4">
				<h1 className="text-black font-bold text-xs dark:text-white">Activity</h1>
				{notifications.length !== 0 && notifications.map(({ asset, notiType, notiCreatedBy, commentText }, index) => asset && <ActivityCard key={index} sentBy={"Rohan Roy"} notiType={notiType} by={notiCreatedBy.username} imgPost={asset.images.image_url} imgBy={notiCreatedBy.avatar} comment={commentText || ""} notiCreatedBy={notiCreatedBy._id} />)}
			</div>
		</div>
	);
}

export default Notifications;
