import { LinearProgress } from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import AllPosts from "../componnent/AllPosts";
import PostCard from "../componnent/Cards/PostCard";
import SalesCard from "../componnent/Cards/SalesCard";
import useDarkLightMode from "../hook/useDarkMode";

function Home({ globalLoading }) {
	const user = JSON.parse(localStorage.getItem("user"));

	var [array, setArray] = useState([]);
	var [secoud, setSecoud] = useState(0);
	var [prevScrollLeft, setScrollLeft] = useState(10);
	const divRref = useRef(null);
	// useEffect(() => {
	// 	// const interval = setInterval(() => {
	// 	//   // setSeconds(seconds => seconds + 1);
	// 	// }, 1000);
	// 	// return () => clearInterval(interval);
	// 	if (array.length === 0) {
	// 		fetch("https://us-central1-niftyagency-36aa8.cloudfunctions.net/getTickerData")
	// 			.then((res) => {
	// 				// return resolve(res.json())
	// 				// console.log(res.json())
	// 				return res.json();
	// 			})
	// 			.then((data) => {
	// 				console.log(data);
	// 				setArray(data);
	// 			})
	// 			.catch((err) => {
	// 				console.error(err);
	// 				// return resolve({"status": "success"})
	// 			});
	// 	} else {
	// 		// console.log("Already loaded")

	// 		const interval = setInterval(() => {
	// 			setSecoud((secouds) => secouds + 1);

	// 			// console.log(secoud)
	// 			// console.log(divRref.current.scrollLeft)
	// 			if (divRref.current.scrollLeft != prevScrollLeft) {
	// 				setScrollLeft(divRref.current.scrollLeft);
	// 				divRref.current.scrollTo(secoud, 0);
	// 			} else {
	// 				setSecoud(0);
	// 				divRref.current.scrollTo(0, 0);
	// 			}
	// 		}, 25);
	// 		return () => {
	// 			// console.log("Clear")
	// 			clearInterval(interval);
	// 		};
	// 	}
	// }, [array, secoud]);

	console.log(array);
	return (
		<>
			<div className="bg-secondary-background min-h-screen  pt-4 pb-10 dark:bg-primary-background">
				{globalLoading && (
					<div
						style={{
							position: "fixed",
							width: "100%",
							top: 0,
							left: 0,
							right: 0,
							zIndex: 99999,
						}}
					>
						<LinearProgress color="error" />
					</div>
				)}
				<div className="">
					{array.length > 0 && (
						<div className="px-4 sticky top-0 z-10 bg-secondary-background left-0 right-0 dark:bg-primary-background ">
							<div className="flex justify-between pt-4">
								<h2 className="text-black  font-bold text-xs dark:text-white">Top NFT Sales (last hour)</h2>
								{/* /!* <h2 className="font-bold text-xs text-[#F99D5E] cursor-pointer">see all</h2> *!/ */}
							</div>
							<div ref={divRref} className=" scroll py-4 flex overflow-x-scroll scrollbar-hide  w-full space-x-2">
								{array.map((item) => (
									<SalesCard title={item.name} isUpAverage={item.isUpAverage} isUpSale={item.isUpSale} img={item.src.replaceAll("40", "50")} salesValue={item.salesValue} salesPercent={item.salesPercent} aurValue={item.averageValue} aurPercent={item.averagePercent} />
								))}
							</div>
						</div>
					)}
					{/* <div className="w-full h-[1px] bg-gray-900"></div> */}
					<div className="">
						<AllPosts />
					</div>
				</div>
			</div>
		</>
	);
}

export default Home;
