import React from "react";

function ActivityCard({ by, imgPost, notiType, imgBy, comment, notiCreatedBy }) {
	return (
		<div>
			<div className="flex mt-2 space-x-3 items-center justify-between ">
				<div className="flex justify-start space-x-2 items-center">
					<img
						src={imgBy}
						alt=""
						className="rounded-full h-12 w-12 cursor-pointer"
						onClick={() => {
							window.location.href = `/profile/${notiCreatedBy}`;
						}}
					/>
					<div className="text-black text-xs dark:text-white">
						<div className="">
							<h1 className="font-bold">
								{by}
								<span className="text-xs font-medium">{`, ${notiType === "like" ? "Liked" : 'commented "' + comment + '" on'} an NFT you posted!`}</span>
							</h1>
						</div>
					</div>
				</div>
				<img src={imgPost} alt="" className="rounded-lg h-12 w-12" />
			</div>
		</div>
	);
}

export default ActivityCard;
