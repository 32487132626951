import "./message.css";
import { format } from "timeago.js";

export default function Message({ message, own }) {
  return (
    <div className={own ? "message own" : "message"}>
      <div className="flex space-x-2 items-center">
        {/* <img
          className="h-6 w-6 rounded-full bg-cover  "
          src="https://images.pexels.com/photos/3686769/pexels-photo-3686769.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
          alt=""
        /> */}
        <p className={`rounded-xl px-4 py-2 ${own ? "bg-[#F99D5E] text-white dark:text-black": "bg-gray-700 text-white dark:text-black"}`}>{message.text}</p>
      </div>
      <div className="messageBottom">{format(message.createdAt)}</div>
    </div>
  );
}
