import React from "react";
import "../componnent/messenger/messenger.css";
import ChatAccountCard from "../componnent/Cards/ChatAccountCard";
import axios from "axios";
// import Topbar from "../../components/topbar/Topbar";
import Conversation from "../componnent/messenger/HelperComps/conversations/Conversation";
import Message from "../componnent/messenger/HelperComps/message/Message";
import ChatOnline from "../componnent/messenger/HelperComps/chatOnline/ChatOnline";
import { useContext, useEffect, useRef, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { Axios } from "../core/axios";
// import { useAuth } from "../../contexts/AuthContext";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { io } from "socket.io-client";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import useDarkLightMode from "../hook/useDarkMode";

export default function Messenger(props) {
	console.log(props);
	const [conversations, setConversations] = useState([]);
	const [currentChat, setCurrentChat] = useState(props.chatData || null);
	const [currentChatUser, setCurrentChatUser] = useState(null);
	const [messages, setMessages] = useState([]);
	const [newMessage, setNewMessage] = useState("");
	const [arrivalMessage, setArrivalMessage] = useState(null);
	const [onlineUsers, setOnlineUsers] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const socket = useRef();
	const navigate = useNavigate();

	const { id } = useParams();

	const { _id } = JSON.parse(localStorage.getItem("user")) || {};
	console.log(_id);

	useEffect(() => {
		console.log("--inside_id---", id);
		if (!id) {
			return;
		}
		const fun = async () => {
			setSidePanel(false);
			const res = await Axios.get(`/conversations/find/${id}/${_id}`);
			console.log("res abcd: ", res);
			if (!res.data) {
				// console.log(user);

				const newConversation = await Axios.post(`/conversations`, {
					senderId: _id,
					receiverId: id,
				});
				console.log(newConversation);
				const res2 = await Axios.get(`/conversations/find/${id}/${_id}`);
				console.log("res2: ", res2);
				setCurrentChat(res2.data);

				return;
			}
			setCurrentChat(res.data);
		};

		fun();

		const name = async () => {
			console.log("currentCHat: ", id);
			console.log("lkshdfklsdhf: ", id);
			const data = await Axios.get(`/profile/${id}`);
			console.log("data.data.data ; ", data.data.data);
			setCurrentChatUser(data.data.data);
			// setCurrentChat(data.data);
		};
		name();
	}, [id, _id]);

	const [sidePanel, setSidePanel] = useState(false);

	// const { user } = useContext(AuthContext);
	const { user } = useAuth();
	// console.log(user);
	const scrollRef = useRef();

	useEffect(() => {
		socket.current = io("https://api.gaggle.art");
		socket.current.on("getMessage", (data) => {
			setArrivalMessage({
				sender: data.senderId,
				text: data.text,
				createdAt: Date.now(),
			});
			console.log("getMessage", data);
		});
	}, []);

	useEffect(() => {
		arrivalMessage && currentChat?.members.includes(arrivalMessage.sender) && setMessages((prev) => [...prev, arrivalMessage]);
	}, [arrivalMessage, currentChat]);

	useEffect(() => {
		socket.current.emit("addUser", _id);
		console.log("emit addUser");
		socket.current.on("getUsers", (users) => {
			setOnlineUsers(onlineUsers.followings.filter((f) => users.some((u) => u._id === f)));
		});
	}, [_id]);

	useEffect(() => {
		const getFriends = async () => {
			// const res = await axios.get("/users/friends/" + currentId);
			// setFriends(res.data);
			const { data } = await Axios.get(`/users/followers-followings-list/${_id}`);
			// console.log(data);
			// setFollowers(data.data.followers);
			setOnlineUsers(data.data.followings);
		};

		getFriends();
	}, []);

	useEffect(() => {
		const getConversations = async () => {
			try {
				const res = await Axios.get("/conversations/" + _id);
				console.log("res.data: : :", res.data);
				setConversations(res.data);
			} catch (err) {
				console.log(err);
			}
		};
		getConversations();
	}, [_id]);

	useEffect(() => {
		// navigate(`/messenger/${currentChat.members[1]}`);
		setIsLoading(true);
		const getMessages = async () => {
			try {
				const res = await Axios.get("/messages/" + currentChat?._id);
				setMessages(res.data);
				setIsLoading(false);
			} catch (err) {
				console.log(err);
				setIsLoading(false);
			}
		};
		getMessages();
	}, [currentChat]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (newMessage.trim() === "") return;
		const message = {
			sender: _id,
			text: newMessage,
			conversationId: currentChat._id,
		};

		const receiverId = currentChat.members.find((member) => member !== _id);

		socket.current.emit("sendMessage", {
			senderId: _id,
			receiverId,
			text: newMessage,
		});

		try {
			const res = await Axios.post("/messages", message);
			setMessages([...messages, res.data]);
			setNewMessage("");
		} catch (err) {
			console.log(err);
		}
	};

	const menuClick = () => {
		setSidePanel(!sidePanel);
	};

	const onSelectContact = (c) => {
		setSidePanel(false);
		console.log("c: ", c);
		var friendId = c.members.find((m) => m !== _id);
		navigate(`/messenger/${friendId}`);
	};

	useEffect(() => {
		if (id) {
			return;
		}
		setSidePanel(true);
	}, []);

	useEffect(() => {
		scrollRef.current?.scrollIntoView({ behavior: "smooth" });
	}, [messages]);

	// useDarkLightMode()
	return (
		<>
			{isLoading && (
				<div
					style={{
						position: "fixed",
						width: "100%",
						top: 0,
						left: 0,
						right: 0,
						zIndex: 99999,
					}}
				>
					<LinearProgress color="error" />
				</div>
			)}

			<div className="bg-secondary-background min-h-screen dark:bg-primary-background">
				<div
					className="flex justify-between sticky top-0 bg-secondary-background z-10 space-x-2 border-b-2 pb-2 px-4 items-center dark:border-gray-700 dark:bg-primary-background"
					style={{
						display: currentChatUser ? "flex" : "none",
						background: "#F99D5E",
						// background: "rgb(59 59 59)",
					}}
				>
					<HiOutlineDotsVertical onClick={menuClick} size={25} className="cursor-pointer text-black dark:text-white" />
					{currentChatUser ? (
						<div
							className="flex p-4 text-xl font-bold border-gray-700 text-black dark:text-white"
							style={{
								justifyContent: "center",
								alignItems: "center",
								cursor: "pointer",
							}}
							onClick={() => navigate(`/profile/${currentChatUser._id}`)}
						>
							<img
								className="h-6 w-6 rounded-full bg-cover"
								style={{
									marginRight: "10px",
								}}
								src={currentChatUser.avatar}
								alt=""
							/>
							<h1>{`${currentChatUser.username}`}</h1>
						</div>
					) : (
						<div
							className="flex p-4 text-xl font-bold border-gray-700 text-black dark:text-white"
							style={{
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<h1>{`Gaggle`}</h1>
						</div>
					)}
					{/* <ChatOnline onlineUsers={onlineUsers} currentId={_id} /> */}
				</div>

				<div className={`grid grid-cols-10 gap-4 text-black relative ${currentChat ? "" : "messenger_root"} dark:text-white`}>
					<div
						className={` p-4 ${sidePanel ? "bg-secondary-background col-span-10 sm:col-span-2 dark:bg-primary-background" : "-translate-x-full absolute "}  transition duration-150 ease-out  sm:shadow-md sm:shadow-gray-300 flex-col pr-4 border-gray-700 dark:sm:shadow-black`}
						style={{
							position: "fixed",
							height: "100%",
							overflow: "scroll",
						}}
					>
						{/* <input type="text" placeholder="Search for Friends" className="mb-10 outline-none bg-transparent border px-4 py-2 rounded-lg w-full" /> */}
						{conversations.map((c) => (
							<div onClick={() => onSelectContact(c)}>
								<Conversation conversation={c} currentUser={user} />
							</div>
						))}
					</div>
					<div className={`flex flex-col justify-center ${sidePanel ? "col-span-10 hidden sm:inline-flex" : "col-span-10"}  px-4 min-h-screen`}>
						{/* {currentChatUser && (
						<div className="flex p-4 text-xl font-bold border-b-2 border-gray-700">
							<h1>{`${currentChatUser.firstName} ${currentChatUser.lastName}`}</h1>
						</div>
					)} */}

						{currentChat ? (
							<>
								<div
									className="flex flex-col w-full h-full"
									style={{
										marginTop: "60px",
									}}
								>
									<div className="flex-grow">
										{messages.map((m) => (
											<div ref={scrollRef}>
												<Message message={m} own={m.sender === user._id} senderAvatar={user.avatart} receiverAvatart={"sd"} />
											</div>
										))}
									</div>
									<div className="scrollbar-hide bg-secondary-background pt-6 w-full flex items-center space-x-4 sticky bottom-0 pb-20 left-0 right-0 dark:bg-primary-background ">
										<textarea rows={1} className="bg-gray-100 w-full  scrollbar-hide border px-4 p-2 rounded-md dark:bg-transparent" placeholder="write something..." onChange={(e) => setNewMessage(e.target.value)} value={newMessage}></textarea>
										<button className="bg-gray-600 rounded-lg shadow-md hover:shadow-lg transition duration-150 ease-out hover:scale-105 transform shadow-black px-4 py-2 text-white dark:text-black" onClick={handleSubmit}>
											Send
										</button>
									</div>
								</div>
							</>
						) : (
							<img src="./icons/gaggle_chat.svg" className="mx-auto"></img>
						)}
					</div>
					{/* {currentChat ? null : (
						<div
							onClick={() => {
								window.location.href = "/discord_chat";
							}}
							style={{
								padding: "10px",
								borderRadius: "50%",
								backgroundColor: "#7289DA",
								position: "fixed",
								bottom: "70px",
								right: "15px",
							}}
						>
							<img src="/icons/hash_icon.svg" width={30} height={30} alt="inbox" className="" />
						</div>
					)} */}
					{/* {currentChat ? null : (
						<div
							onClick={() => {
								window.open("https://t.me/gagglechat", "_blank");
								// window.location.href = "https://t.me/Dipankarshah";
							}}
							style={{
								padding: "10px",
								borderRadius: "50%",
								backgroundColor: "#F99D5E",
								position: "fixed",
								bottom: "70px",
								left: "15px",
							}}
						>
							<img src="/icons/telegram.svg" width={30} height={30} alt="inbox" className="" />
						</div>
					)} */}
				</div>
			</div>
		</>
	);
}
