import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import PostCard from "../componnent/Cards/PostCard";
import { Axios } from "../core/axios";
import { LinearProgress, Button, Box } from "@mui/material";
import SingleItem from "../componnent/UserProfile/SingleItem";
import ReactGA from "react-ga";
import AllComment from "../componnent/AllPost/AllComment/AllComment";
import SavedImageComp from "./SavedImageComp";

function Saved({ globalLoading }) {
	const [params] = useSearchParams();
	const [open, setOpen] = useState(false);
	const [posts, setPosts] = useState([]);
	const [isLoadable, setLoadable] = useState(false);
	const [isLoading, setLoading] = useState(true);
	const [isSelectMode, setIsSelectMode] = useState(false);
	const [selectedNfts, setSelectedNfts] = useState([]);
	const [isLoadedFirstTime, setLoadedFirstTime] = useState(false);

	const loadPostsAgain = async () => {
		// setPosts([]);
		window.location.reload()
	}


	const loadPosts = async () => {
		// Axios.defaults.baseURL = "http://localhost:3001/api/v1/";
		try {
			const query = params.get("search");
			const skip = posts.length;

			setLoading(true);
			let url = `/assets/saved?skip=0`;

			const { data } = await Axios.get(url);
			console.log(data);

			setLoading(false);

			setLoadable(data.data.length === 20);

			setPosts([...posts, ...data.data]);
			setLoadedFirstTime(true);
		} catch (error) {
			setLoading(false);
			alert(error.message);
			setLoadedFirstTime(true);
		}
	};

	useEffect(() => {
		loadPosts();
	}, []);

	useEffect(() => {
		ReactGA.pageview(window.location.pathname);
	}, []);

	return (
		<>
		<div className="bg-secondary-background min-h-screen dark:bg-primary-background">
			{(isLoading || globalLoading) && (
				<div
					style={{
						position: "fixed",
						width: "100%",
						top: 0,
						left: 0,
						right: 0,
						zIndex: 99999,
					}}
				>
					<LinearProgress color="error" />
				</div>
			)}
			<div className="p-4 pb-0">
				<h1 className="text-black font-bold text-xs dark:text-white">Your Saved NFTs</h1>
			</div>
			{/* <div className="px-4 py-4 space-y-4 w-full sm:w-[500px] flex flex-col mx-auto"></div> */}
			<div className="grid grid-cols-4 gap-2 p-4">
				{posts.length === 0 && !isLoading ? (
					<div>
						<h1>Nothing to display.</h1>
					</div>
				) : (
					posts.map((post, idx) => console.log(post))
				)}
			</div>
			<div className="grid grid-cols-4  gap-4 p-4">
				{/* {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
								<div>
									<img src="/images/nft1.png" className="h-16 rounded-lg" />
									</div>
								))} */}
				{posts.map((post, idx) => (
					// <div>
					// 	<img onClick={()=>{
					// 		setOpen(true);
					// 	}} src={post.asset.images.image_url || "'https://via.placeholder.com/200x200.png?text=Image%20not%20found.'"} className="w-full sm:h-[200px] md:h-[400px] rounded-lg bg-cover" key={idx} />
					// 	{/* <SingleItem isSelectMode={isSelectMode} nft={post.asset} key={idx} setSelectedNfts={setSelectedNfts} allselectedNfts={selectedNfts} /> */}
					// 	{open && <AllComment open={open} nft={post.asset} handleClose={handleClose} />}
					// </div>
					<SavedImageComp post={post} idx={idx} loadPostsAgain={loadPostsAgain}/>
				))}
			</div>
			{isLoadable && (
				<h1 className="text-black cursor-pointer px-4 text-md text-bold pb-10 dark:text-white" onClick={loadPosts}>
					{isLoading ? "Loading..." : "Load More"}
				</h1>
			)}
		</div>

			</>
	);
}

export default Saved;
