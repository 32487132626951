import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { Axios } from "../../core/axios";
import AllComment from "../AllPost/AllComment/AllComment";
import ViewVideoCard from "./ViewVideoCard";

function PostCard({ nft, img }) {
	const { user } = useAuth();
	const [isDark, setIsDark] = useState(true);
	const [toggleSavePost, setToggleSavePost] = useState(false);
	const [toggleComment, setToggleComment] = useState(false);
	const navigate = useNavigate();
	// console.log(nft);
	const [open, setOpen] = useState(false);
	const [showVideo, setShowVideo] = useState(false);
	const [isLiked, setLiked] = useState(nft.isLovedMySelf);
	const [saved, setSaved] = useState(false);
	const [numberOfLoves, setNumberOfLoves] = useState(nft.numberOfLoves);
	const [numberOfComments, setNumberOfComments] = useState(nft.numberOfComments);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const handleCloseVideo = () => setShowVideo(false);
	const [comment, setComment] = useState("");

	useEffect(() => {
		let mode = localStorage.getItem("theme");
		if (mode === "dark") {
			setIsDark(true);
		}
		if (mode === "light") {
			setIsDark(false);
		}
	}, []);

	const handleComment = async (e) => {
		if (user) {
			e.preventDefault();

			try {
				setNumberOfComments(numberOfComments + 1);
				setComment("");

				await Axios.post(`/assets/${nft._id}/comments`, {
					theComment: comment,
				});
			} catch (error) {
				alert(error.message);
			}
		} else {
			navigate("/addwallet");
		}
	};

	const handleLike = async () => {
		if (user) {
			try {
				setLiked(!isLiked);
				setNumberOfLoves(isLiked ? numberOfLoves - 1 : numberOfLoves + 1);

				await Axios.put(`/assets/reaction/${nft._id}`);
			} catch (error) {
				setLiked(!isLiked);
				alert(error.message);
				navigate("/addwallet");
			}
		} else {
			navigate("/addwallet");
		}
	};

	const handleMessage = async () => {
		navigate(`/messenger/${nft.author._id}`);
	};

	const handleSave = async () => {
		if (user) {
			try {
				// setSaved(!saved);
				await Axios.put(`/assets/saved`, {
					assetId: nft._id,
				});
			} catch (err) {
				// setSaved(!saved);
				alert(err.message);
			}
		} else {
			navigate("/addwallet");
		}
	};

	const deleteSave = async () => {
		if (user) {
			try {
				await Axios.delete(`/assets/saved`, {
					assetId: nft._id,
				});
			} catch (err) {
				alert(err.message);
			}
		} else {
			navigate("/addwallet");
		}
	};

	return (
		<>
			<div className="h-full w-full rounded-lg">
				<div className=" bg-white drop-shadow-2xl rounded-xl dark:bg-black ">
					<div className="relative">
						<img
							style={{
								objectFit: nft.images.image_url.includes(".svg") ? "contain" : "cover",
							}}
							src={nft.images.image_url || "//via.placeholder.com/200x200.png?text=Image%20not%20found."}
							alt=""
							className="object-cover rounded-xl bg-cover h-[500px] w-full"
						/>
						<div className="h-12 absolute top-0 bg-[#ffffff] rounded-t-lg w-full justify-between flex items-center px-4 dark:bg-black">
							<div onClick={() => navigate(`/profile/${nft.author._id}`)} className="flex space-x-2 items-center cursor-pointer">
								<img src={nft.author.avatar} alt={nft.author.username} className="h-6 w-6 rounded-full bg-orange-600"></img>
								<div className="flex flex-col">
									<h1
										className="text-black font-bold text-sm dark:text-white"
										style={{
											fontFamily: "Roboto Condensed, sans-serif",
											fontWeight: "700",
										}}
									>
										{nft.author.username}
									</h1>
									<h1
										className="text-black font-medium text-xs dark:text-white"
										style={{
											fontFamily: "Roboto Condensed, sans-serif",
											fontWeight: "500",
										}}
									>
										{nft.name}
									</h1>
								</div>
							</div>
							{/* {!saved && (
								<img
									style={{
										cursor: "pointer",
									}}
									onClick={handleSave}
									src={isDark ? "/icons/savePost.svg" : "/icons/savePostLight.svg"}
									alt=""
								/>
							)} */}
						</div>
						{/* Put the bottom icons here*/}
						{nft.animation.url ? (
							<div
								className="absolute top-1/2 left-1/2"
								style={{
									transform: "translate(-50%, -50%)",
									cursor: "pointer",
								}}
								onClick={() => {
									setOpen(true);
								}}
								title="Play animation"
							>
								<img src="/icons/play.svg" height={50} width={50} />
								{/*{showVideo && <ViewVideoCard open={showVideo} nft={nft} handleClose={handleCloseVideo} />}*/}
							</div>
						) : null}
					</div>
					<div className="flex relative justify-between items-center w-full mt-2">
						<div className="flex justify-start">
							<img height={50} title="Open Comments" onClick={() => setToggleComment(!toggleComment)} src={`${!toggleComment ? "/icons/comment.svg" : "/icons/close.svg"}`} alt="" className="hover:scale-105 transition duration-150 ease-out transform cursor-pointer" />
							<img height={50} title="Chat Now" onClick={handleMessage} src="/icons/message.svg" alt="" className="hover:scale-105 transition duration-150 ease-out transform cursor-pointer" />
						</div>
						<div className="flex absolute bottom-0 right-5 justify-end items-center">
							<div className="flex item-center justify-center items-center space-x-2">
								<h2 className="flex justify-center font-bold text-black dark:text-white ">{numberOfLoves} </h2>
								<img title={isLiked ? "Unlike" : "Like"} onClick={handleLike} src={isLiked ? "/icons/likedButton.svg" : "/icons/likeButton.svg"} height={35} width={70} alt="" className="hover:scale-105 transition duration-150 ease-out transform cursor-pointer" />
							</div>
							<div
								className="flex justify-center items-center"
								onClick={() => {
									// window.open(nft.permalink, "_blank");
									// Open Custom Tab with URL and make it's height full screen but width fixed
									window.open(nft.permalink, "_blank", "width=800,height=800,left=100,top=100");
								}}
								style={{
									height: nft.permalink.includes("opensea") ? "75px" : "50px",
									width: nft.permalink.includes("opensea") ? "50px" : "43px",
								}}
								title="View the NFT"
							>
								<img src={nft.permalink.includes("opensea") ? "/icons/opensea.svg" : isDark ? "/icons/linkWhite.svg" : "/icons/link.svg"} height={nft.permalink.includes("opensea") ? 35 : 40} width={nft.permalink.includes("opensea") ? 35 : 50} alt="" className="hover:scale-105 transition duration-150 ease-out transform cursor-pointer" />
							</div>
						</div>
					</div>
					{toggleComment && (
						<div className=" w-full rounded-b-lg text-white text-xs p-4 bg-black">
							<div className="flex space-x-2 font-bold">
								<h1 className="">{nft.name}</h1>
							</div>
							<div onClick={handleOpen} className="cursor-pointer text-gray-400">
								<h1 className="">view all {numberOfComments} comments</h1>
							</div>
							<div className="pt-4 flex justify-between items-center">
								<div className=" flex space-x-2">
									<img src={nft.author.avatar} alt="" className="h-6 w-6 rounded-full" />
									<input value={comment} onChange={(e) => setComment(e.target.value)} type="text" placeholder="Add a comment..." className="outline-none bg-transparent text-xs text-white" />
								</div>
								<h1 onClick={handleComment} className="text-sm cursor-pointer text-white text-bold">
									POST
								</h1>
							</div>
						</div>
					)}
				</div>
			</div>
			{open && <AllComment open={open} nft={nft} handleClose={handleClose} />}
		</>
	);
}

export default PostCard;
